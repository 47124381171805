import { createContext, useContext } from "react";
import { useQuery } from "./use-query";

export const ConfigContext = createContext<Config>({} as any);

export type CrossmintConfig = {
  collectionTitle: string;
  collectionPhoto: string;
  clientId: string;
  environment: string;
  mintConfig: { type: string };
};

export type Config = {
  crossmint: CrossmintConfig;
  crossmint1982Collection: CrossmintConfig;
  chainId: number;
  contractAddress: string;
  address1982Collection: string;
  abi1982Collection: any;
  maxTokensPerTransaction1982Collection: number;
  contractABI: any;
  maxTokensPerTransaction: number;
  heritagePassCostDollars: number;
  costDollars1982Collection: number;
  abis: Record<string, any>;
};
export const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const { data } = useQuery<Config>("/api/config");
  if (!data) {
    return null;
  }
  data.abis = {
    [data.address1982Collection]: data.abi1982Collection,
    [data.contractAddress]: data.contractABI,
  };
  return <ConfigContext.Provider children={children} value={data} />;
};

export const useConfig = () => useContext(ConfigContext);
