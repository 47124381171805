import { styled, Button, Typography, Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useEnsAvatar,
  useEnsName,
} from "wagmi";
import { useAuth } from "../hooks/useAuth";

export function Profile(props: { handleOpen: any }) {
  const {
    isLoading: isAuthLoading,
    isAuthenticated,
    magicUserMetadata,
    logout,
  } = useAuth();
  const { address, connector, isConnected } = useAccount();
  const { data: ensAvatar } = useEnsAvatar({ addressOrName: address });
  const { data: ensName } = useEnsName({ address });
  const { disconnect } = useDisconnect();

  function fullyLogout() {
    if (magicUserMetadata) {
      logout();
    }
    if (isConnected) {
      disconnect();
    }
  }

  if (isAuthLoading) {
    return null;
  }
  if (!isAuthenticated) {
    return (
      <Button
        component={Link}
        to={"/sign-in"}
        variant={"text"}
        color={"inherit"}
        size={"large"}
      >
        Sign In
      </Button>
    );
  }
  if (magicUserMetadata) {
    return (
      <Stack
        flexDirection={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
      >
        <Button
          component={Link}
          to={"/redeem"}
          variant={"text"}
          color={"inherit"}
          size={"large"}
        >
          {magicUserMetadata.email}
        </Button>
        <Button
          onClick={fullyLogout}
          variant={"text"}
          color={"inherit"}
          size={"large"}
        >
          Sign out
        </Button>
      </Stack>
    );
  }

  const displayAddress = `${address?.substring(0, 6)}...${address?.substring(
    address?.length - 5
  )}`;

  const content = isConnected ? (
    <>
      <Button
        component={Link}
        to={"/redeem"}
        variant={"text"}
        color={"inherit"}
        size={"large"}
      >
        Connected: {ensName ? `${ensName}` : displayAddress}
      </Button>
      <Button
        variant={"text"}
        onClick={fullyLogout}
        color={"inherit"}
        size={"large"}
      >
        Disconnect
      </Button>
    </>
  ) : (
    <Button
      variant={"text"}
      onClick={props.handleOpen}
      color={"inherit"}
      size={"large"}
    >
      Connect wallet
    </Button>
  );

  return (
    <Stack
      flexDirection={"row"}
      justifyContent={"flex-end"}
      alignItems={"center"}
    >
      {content}
    </Stack>
  );
}
