import { Button } from "@mui/material";
import { useConfig } from "../common/core/config";
import {
  useAccount,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { ethers } from "ethers";
import WalletDialog from "./WalletDialog";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { useHeritagePassMintPrice, useMintPrice } from "../hooks/useMintPrice";

const buyButtonProps = {
  variant: "contained",
  color: "primary",
  sx: {
    minWidth: "195px",
    minHeight: "49px",
  },
} as const;
export function MintWithEthButton({ quantity }: { quantity: number }) {
  const config = useConfig();
  const { isConnected } = useAccount();
  const ethPrice = useHeritagePassMintPrice();

  const {
    config: writeConfig,
    error: prepareError,
    isError: isPrepareError,
  } = usePrepareContractWrite({
    addressOrName: config.contractAddress,
    contractInterface: config.contractABI,
    functionName: "mintTokens",
    args: [quantity],
    chainId: config.chainId,
    overrides: {
      value: ethPrice.mul(quantity),
    },
  });
  const { data, error, isError, write } = useContractWrite(writeConfig);

  const { isLoading, isSuccess } = useWaitForTransaction({
    hash: data?.hash,
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  if (isSuccess) {
    return <Navigate to={"/mint-successful"} />;
  }
  if (!isConnected) {
    return (
      <>
        <Button {...buyButtonProps} onClick={() => setDialogOpen(true)}>
          Buy with ETH
        </Button>
        <WalletDialog
          dialogOpen={dialogOpen}
          handleClose={() => setDialogOpen(false)}
        />
      </>
    );
  }
  if (error || prepareError) {
    console.error({ error, prepareError });
  }
  if (prepareError) {
    const message = (prepareError as any).reason?.replace(
      "execution reverted:",
      ""
    );
    return (
      <Button disabled={true} {...buyButtonProps}>
        {message ?? "Unknown error occurred"}
      </Button>
    );
  }
  if (!write) {
    return (
      <Button disabled={true} {...buyButtonProps}>
        Loading...
      </Button>
    );
  }
  return (
    <Button {...buyButtonProps} disabled={isLoading} onClick={() => write()}>
      {isLoading ? "Purchase in progress..." : "Buy with ETH"}
    </Button>
  );
}
