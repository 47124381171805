import { Box, Grid, Typography, Modal, ButtonBase, Link } from "@mui/material";
import Logo from "../icons/vb-logo.svg";
import LogoDiamond from "../icons/vb-diamond.svg";
import Discord from "../icons/discord-brands.svg";
import Instagram from "../icons/instagram-brands.svg";
import Pinterest from "../icons/pinterest-brands.svg";
import Twitter from "../icons/twitter-brands.svg";
import Facebook from "../icons/facebook-brands.svg";
import { useState } from "react";
import { TermsConditions } from "./terms-conditions";
import {
  discordLink,
  facebookLink,
  instagramLink,
  pinterestLink,
  twitterLink,
} from "../links";

export function Footer() {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  return (
    <Box minHeight={"100px"} width={"100vw"}>
      <Grid container sx={{ backgroundColor: "rgb(51, 51, 51)", py: 3 }}>
        <Grid item xs={1} sm={4} />
        <Grid
          item
          xs={10}
          sm={4}
          display={"flex"}
          alignItems={"center"}
          flexDirection="column"
        >
          <img
            height="72"
            src={LogoDiamond}
            alt="Diamond"
            style={{ marginBottom: "1rem" }}
          />
          <img height="72" src={Logo} alt="Vera Bradley Logo" />
          <Box
            display="flex"
            width={"50%"}
            justifyContent={"space-around"}
            my={3}
          >
            <Link
              href={discordLink}
              target={"_blank"}
              rel={"noreferrer noopener"}
            >
              <img height="25" src={Discord} alt="discord logo" />
            </Link>
            <Link
              href={facebookLink}
              target={"_blank"}
              rel={"noreferrer noopener"}
            >
              <img height="25" src={Facebook} alt="twitter logo" />
            </Link>
            <Link
              href={instagramLink}
              target={"_blank"}
              rel={"noreferrer noopener"}
            >
              <img height="25" src={Instagram} alt="insta logo" />
            </Link>
            <Link
              href={pinterestLink}
              target={"_blank"}
              rel={"noreferrer noopener"}
            >
              <img height="25" src={Pinterest} alt="pinterest logo" />
            </Link>
            <Link
              href={twitterLink}
              target={"_blank"}
              rel={"noreferrer noopener"}
            >
              <img height="25" src={Twitter} alt="twitter logo" />
            </Link>
          </Box>
          <Link
            href={"https://verabradley.com/pages/verabradley"}
            target={"_blank"}
            rel={"noreferrer noopener"}
            textTransform={"uppercase"}
            underline={"none"}
            sx={{ color: "white", fontSize: "13px" }}
          >
            #verabradley
          </Link>
        </Grid>
        <Grid item sm={4} xs={1} />
      </Grid>

      <Grid container sx={{ bgcolor: "grey.100", py: 2 }}>
        <Grid
          item
          xs={6}
          sm={6}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection="column"
        >
          <Typography align="center" variant="caption">
            © 2022 Vera Bradley. All Rights Reserved
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection="column"
        >
          <ButtonBase color="inherit" onClick={() => setModalOpen(true)}>
            <Typography align="center" variant="caption">
              Terms & Conditions
            </Typography>
          </ButtonBase>
        </Grid>
        <Grid item sm={4}></Grid>
      </Grid>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <TermsConditions />
        </Box>
      </Modal>
    </Box>
  );
}
