import { Box, Container, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { useInterval } from "../useInterval";

const slogans = [
  "female-led ingenuity",
  "innovative design",
  "inspired community",
];
const useSlogan = () => {
  const [index, setIndex] = useState(0);
  useInterval(() => {
    setIndex((s) => {
      return (s + 1) % slogans.length;
    });
  }, 1500);
  return slogans[index];
};

export function HeresToBanner() {
  const slogan = useSlogan();
  return (
    <Container
      component={"section"}
      sx={{
        px: {
          xs: 0,
          md: 2,
        },
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        py={3}
        mb={8}
        sx={{
          backgroundColor: "#132b4c",
          width: "100%",
        }}
      >
        <Typography
          align={"left"}
          paragraph={true}
          variant={"h3"}
          sx={{ textTransform: "uppercase", color: "white" }}
        >
          Here's to 40 years of...
        </Typography>
        <Typography
          align={"left"}
          variant={"h3"}
          sx={{ color: "white", fontFamily: "Avenir-Book" }}
        >
          {slogan}
        </Typography>
        <Divider
          sx={{
            borderColor: "white",
            width: {
              xs: "75%",
              sm: "66%",
              md: "50%",
            },
          }}
        />
      </Box>
    </Container>
  );
}
