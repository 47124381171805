import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { magic } from "../common/auth/magic";
import { CircularProgress } from "@mui/material";
import { useAuth } from "../hooks/useAuth";

export default function Callback() {
  const location = useLocation();

  const { isAuthenticated, loginWithCredential } = useAuth();
  // const [_, setUser] = useContext(UserContext);
  const [params] = useSearchParams();
  // The redirect contains a `provider` query param if the user is logging in with a social provider
  useEffect(() => {
    // let provider = new URLSearchParams(props.location.search).get('provider');
    // provider ? finishSocialLogin() : finishEmailRedirectLogin();
    finishEmailRedirectLogin();
  }, [location.search]);

  // `loginWithCredential()` returns a didToken for the user logging in
  const finishEmailRedirectLogin = () => {
    let magicCredential = new URLSearchParams(location.search).get(
      "magic_credential"
    );
    if (magicCredential) {
      loginWithCredential();
    }
  };
  if (isAuthenticated) {
    const next = params.get("next") || "/redeem";
    return <Navigate to={next} />;
  }

  // // Send token to server to validate
  // const authenticateWithServer = async (didToken: string | null) => {
  //   if (!didToken) {
  //     console.error('No didToken passed in');
  //     return;
  //   }
  //   await logIn(didToken);
  //   // Set the UserContext to the now logged in user
  //   let userMetadata = await magic.user.getMetadata();
  //   await setUser({
  //     user: userMetadata,
  //     state: 'loaded',
  //   });
  //   refetch();
  //   navigate('/mint-usd');
  // };

  return <CircularProgress />;
}
