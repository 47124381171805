const serverHost = window.location.origin;

export function apiCall(path: string, init?: RequestInit) {
  return fetch(`${serverHost}${path}`, {
    method: init?.method || "POST",
    credentials: "include",
    ...init,
    headers: {
      "Content-Type": "application/json",
      ...init?.headers,
    },
  });
}
async function postJson(path: string, body: any) {
  const res = await postJsonRequest(path, body);
  return res.json();
}

async function postJsonRequest(path: string, body: any) {
  const res = await apiCall(path, {
    body: JSON.stringify(body),
  });
  return res;
}

export async function registerAddress(
  address: string
): Promise<{ nonce: string }> {
  return postJson("/api/register-address", { address });
}

export async function authenticate({
  signature,
  message,
}: Record<string, string>): Promise<{ nonce: string }> {
  return postJson("/api/authenticate", { signature, message });
}

export async function getConfig() {
  return apiCall("/api/config", {
    method: "GET",
  });
}

export async function redeemBag(body: any) {
  const res = await postJsonRequest("/api/redeem-bag", body);
  const resBody = await res.json();
  if (res.ok) {
    return resBody;
  } else {
    throw new Error(JSON.stringify(resBody));
  }
}
