import { useState } from "react";
import { useConfig } from "../common/core/config";
import { use19282MintPrice } from "../hooks/useMintPrice";
import { Box, Button, Container, Divider, Stack } from "@mui/material";
import swatches from "../assets/swatches_gen.gif";
import { ethers } from "ethers";
import { Crossmint } from "../common/Crossmint";
import { HeaderText, TextBlock } from "../components/Titles";
import { formatNumericPriceUSD } from "../common/utils";
import { MintStatus, useMintStatus } from "../hooks/useMintStatus";
import { Loading } from "../components/Loading";
import { Navigate } from "react-router-dom";
import { MintWithEth1982Button } from "../components/MintEth1982Collection";

export default function Mint1982Page() {
  const [count, setCount] = useState(1);
  const config = useConfig();
  const { mintStatus, isLoading } = useMintStatus(config.address1982Collection);
  const ethPrice = use19282MintPrice();

  if (isLoading) {
    return <Loading />;
  }
  if (mintStatus === MintStatus.CLOSED) {
    return <Navigate to={"/"} />;
  }
  const functionName = "mintTokens";
  const proof = undefined;
  return (
    <Container>
      <main
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#FFFFFF",
        }}
      >
        <Stack
          direction={{
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
          }}
          spacing={{ xs: 2, sm: 2, md: 2, lg: 4 }}
        >
          <Stack direction="column" pt={4}>
            <HeaderText variant={"h2"} align={"left"} mb={4}>
              Mint
            </HeaderText>
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              }}
              spacing={{ xs: 2, sm: 2, md: 8, lg: 8 }}
              alignItems="center"
              justifyContent={"flex-start"}
              height="auto"
              overflow="hidden"
              mb={4}
            >
              <Box
                sx={{
                  height: "200px",
                }}
              >
                <img
                  src={swatches}
                  alt={"Jillybag Cambridge"}
                  style={{ height: "inherit" }}
                />
              </Box>
              <Stack
                direction="row"
                spacing={{ xs: 2, sm: 4, md: 8, lg: 8 }}
                alignItems="top"
                height="auto"
                object-fit="cover"
              >
                <TextBlock>
                  <span style={{ fontWeight: "900", color: "black" }}>
                    1982 Collection NFT
                  </span>
                  <br />
                  <br />
                  <br />
                  <span>
                    <Button
                      onClick={
                        count > 1 ? () => setCount(count - 1) : undefined
                      }
                      variant="text"
                      size={"small"}
                      sx={{ minWidth: "32px" }}
                      color={"inherit"}
                    >
                      -
                    </Button>
                    {"  "}
                    {count}
                    {"  "}
                    <Button
                      size={"small"}
                      onClick={
                        count < config.maxTokensPerTransaction1982Collection
                          ? () => setCount(count + 1)
                          : undefined
                      }
                      sx={{ minWidth: "32px" }}
                      variant={"text"}
                      color={"inherit"}
                    >
                      +
                    </Button>
                  </span>
                </TextBlock>
                <TextBlock>
                  Price
                  <br />
                  <br />
                  <br />${config.costDollars1982Collection}
                </TextBlock>
                <TextBlock>
                  Total
                  <br />
                  <br />
                  <br />$
                  {formatNumericPriceUSD(
                    config.costDollars1982Collection * count
                  )}
                </TextBlock>
              </Stack>
            </Stack>
            <Divider sx={{ width: "60%", textAlign: "center", mb: 4 }} />
            <TextBlock paragraph={true}>
              The 1982 Collection is composed of hand-drawn layers of art that
              share the same hallmarks as Vera Bradley’s prints: optimism,
              happiness, charm, energy and joy.{" "}
              <span style={{ fontFamily: "Avenir-Heavy" }}>
                100% of Vera Bradley's primary net proceeds will be donated to
                the Vera Bradley Foundation for Breast Cancer.
              </span>
            </TextBlock>
            <TextBlock>
              We hope that everyone in the World of Vera Bradley who acquires
              this very special 1982 NFT will change out their profile picture
              background in October to show their support during National Breast
              Cancer Awareness Month.
            </TextBlock>
            <TextBlock>
              Using original Vera Bradley prints (including rare signature pink
              prints in honor of National Breast Cancer Awareness Month) as
              inspiration, the team uses hand-drawn derivatives and generative
              code to create unique assets with a variety of rarity. The
              combination of art, history and technology makes this one of the
              most unique fashion NFT projects to date.
            </TextBlock>
          </Stack>
          <Stack
            sx={{
              backgroundColor: "#f3f3f3",
              color: "#333",
              minHeight: {
                xs: "30vh",
                md: "80vh",
              },
              width: "100%",
            }}
            mb={{
              xs: 2,
            }}
            py={3}
          >
            <Container
              sx={{
                px: {
                  xs: 0,
                  md: 2,
                },
                height: "90%",
                width: "90%",
                margin: "auto",
              }}
            >
              <Box>
                <TextBlock
                  sx={{
                    fontSize: "26px",
                    textAlign: "left",
                    fontWeight: "200",
                  }}
                >
                  Order Summary
                </TextBlock>
                <TextBlock
                  sx={{
                    marginTop: "40px",
                    fontSize: "20px",
                    fontWeight: "900",
                    color: "black",
                  }}
                >
                  <span style={{ float: "left" }}>Subtotal</span>
                  <span style={{ float: "right" }}>
                    $
                    {formatNumericPriceUSD(
                      config.costDollars1982Collection * count
                    )}{" "}
                    | {ethers.utils.formatEther(ethPrice.mul(count))} Eth
                  </span>
                </TextBlock>
              </Box>
              <Divider
                sx={{
                  width: "60%",
                  textAlign: "center",
                  margin: "120px auto 40px auto",
                  borderColor: "black",
                }}
              />
              <Box textAlign="center">
                <Box mb={2}>
                  <MintWithEth1982Button
                    functionName={functionName}
                    merkleProof={proof}
                    quantity={count}
                  />
                </Box>
                <TextBlock textAlign="center">OR</TextBlock>
                <Box mt={2} justifyContent="center" display="flex">
                  <Crossmint
                    configKey={"crossmint1982Collection"}
                    ethPrice={ethPrice}
                    quantity={count}
                  />
                </Box>
              </Box>
            </Container>
          </Stack>
        </Stack>
      </main>
    </Container>
  );
}
