import { Link as MuiLink } from "@mui/material";

import BasicPaperDisplay from "../components/BasicPaperDisplay";
export function MintSuccess() {
  return (
    <BasicPaperDisplay
      title={"Success"}
      body={
        <>
          Your purchase was successful. Return to{" "}
          <MuiLink href={"worldofverabradley.com"}>
            worldofverabradley.com
          </MuiLink>{" "}
          on September 28 to redeem your Jilly Bag(s).
        </>
      }
    />
  );
}
