import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  TypographyProps,
} from "@mui/material";

import { useAccount, useConnect } from "wagmi";
import coinbase from "../assets/Coinbase-icon-symbol-1.png";

function TextBlock(props: TypographyProps) {
  return <Typography align={"left"} paragraph={true} {...props} />;
}

function HeaderText(props: TypographyProps) {
  return (
    <TextBlock color={"#12284B"} variant={"h3"} align={"center"} {...props} />
  );
}

export function WalletSelection() {
  const { isConnected } = useAccount();
  const { connect, connectors } = useConnect({
    onError(error) {
      console.log("Error", error);
    },
  });

  return (
    <>
      <Grid
        container
        spacing={1}
        justifyContent={"space-around"}
        style={{ padding: "10px 0" }}
      >
        {connectors.map((connector) => (
          <Stack direction="row" key={connector.id}>
            <Button
              disabled={!connector.ready}
              onClick={() => connect({ connector })}
            >
              <Card
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  padding: 1,
                  minWidth: {
                    xs: "300px",
                    sm: "430px",
                  },
                }}
              >
                <Stack
                  direction={{
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                  }}
                  spacing={{ xs: 2, sm: 2, md: 2, lg: 4 }}
                >
                  {connector.id === "metaMask" ? (
                    <Box
                      component={"img"}
                      width={"90px"}
                      height={"90px"}
                      src={
                        "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png"
                      }
                      alt={"Metamask"}
                      sx={{
                        mb: {
                          xs: 1,
                        },
                      }}
                    />
                  ) : (
                    <Box
                      component={"img"}
                      width={"80px"}
                      height={"80px"}
                      src={coinbase}
                      alt={"Coinbase logo"}
                      style={{ padding: "4px" }}
                    />
                  )}
                  <Box>
                    <HeaderText variant={"h6"} textAlign="left" sx={{ mt: 1 }}>
                      {connector.name}
                    </HeaderText>
                    <TextBlock>
                      Connect to your {connector.name}
                      {connector.id === "metaMask" ? " wallet extension" : ""}
                    </TextBlock>
                  </Box>
                </Stack>
              </Card>
            </Button>
          </Stack>
        ))}
      </Grid>
    </>
  );
}
