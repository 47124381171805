import {
  Box,
  Button,
  Container,
  Typography,
  Link as MuiLink,
  Stack,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import swatches from "../assets/swatches_gen.gif";
import bag from "../assets/jilly_bags.gif";
export function BuySection() {
  return (
    <Box component={"section"} display={"flex"} alignItems={"center"} mb={8}>
      <Container>
        <Grid spacing={4} container>
          <Grid item md={6}>
            <Stack
              direction="column"
              spacing={2}
              sx={{ textAlign: "center" }}
              alignItems={"center"}
            >
              <Box component={"img"} src={bag} width={"75%"} />
              <Typography variant={"h3"} align={"center"}>
                The Heritage Pass
              </Typography>
              <Typography variant={"body2"}>
                Bridging the digital and physical worlds, each Heritage Pass NFT
                will come with a limited-edition Jilly Bag. Vera Bradley’s
                primary net proceeds from the Heritage Pass collection will be
                donated to the Vera Bradley Foundation for Breast Cancer to
                support critical advancements in breast cancer research.
              </Typography>
              <Typography variant={"body2"}>
                <MuiLink component={Link} to={"/redeem"}>
                  Already have a pass? Click here to redeem.
                </MuiLink>
              </Typography>
            </Stack>
          </Grid>
          <Grid item md={6}>
            <Stack
              direction="column"
              spacing={2}
              sx={{ textAlign: "center" }}
              alignItems={"center"}
            >
              <Box component={"img"} src={swatches} width={"75%"} />
              <Typography variant={"h3"} align={"center"}>
                The 1982 Collection
              </Typography>
              <Typography variant={"body2"}>
                Vera Bradley’s primary net proceeds from The 1982 Collection
                also benefit the Vera Bradley Foundation for Breast Cancer. Sold
                during National Breast Cancer Awareness Month, these signature
                pieces of artwork double as a way to show support for the cause
                by updating your profile picture background to your new NFT.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

/**
 * return (
 *     <Box component={"section"} display={"flex"} alignItems={"center"} mb={8}>
 *       <Container>
 *         <Typography variant={"h3"} align={"center"} paragraph={true}>
 *           The Heritage Pass is sold out
 *         </Typography>
 *         <Typography align={"center"}>
 *           Check out the collection on{" "}
 *           <MuiLink
 *             href={openseaLink}
 *             target={"_blank"}
 *             rel={"noreferrer noopener"}
 *           >
 *             Opensea
 *           </MuiLink>{" "}
 *           for an opportunity to purchase The Heritage Pass on the secondary
 *           market.
 *         </Typography>
 *       </Container>
 *     </Box>
 *   );
 */
