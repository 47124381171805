import { Button } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import PinterestIcon from "@mui/icons-material/Pinterest";
import InstagramIcon from "@mui/icons-material/Instagram";
import Discord from "../icons/discord";

export const SocialIconLinks = () => {
  return (
    <>
      <Button href="https://twitter.com/verabradley">
        <TwitterIcon sx={{ mr: 1, color: "#000000" }} />
      </Button>
      <Button href="https://www.facebook.com/verabradley">
        <FacebookIcon
          href="https://discord.com"
          sx={{ mr: 1, color: "#000000" }}
        />
      </Button>
      <Button href="https://www.pinterest.com/verabradley/">
        <PinterestIcon sx={{ mr: 1, color: "#000000" }} />
      </Button>
      <Button href="https://www.instagram.com/verabradley/">
        <InstagramIcon sx={{ mr: 1, color: "#000000" }} />
      </Button>
      <Button href="https://discord.gg/ud5AU9dNbT">
        <Discord />
      </Button>
    </>
  );
};
