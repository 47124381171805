import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import ScrollUp from './ScrollUp';
import { Footer } from './Footer';

export default function Shell(props: {handleOpen:any}) {
  return (
    <>
      <Header handleOpen={props.handleOpen} />
      <Box sx={{ minHeight: '80vh' }}>
        <Outlet />
      </Box>
      <Footer />
      <ScrollUp />
    </>
  );
}
