import {
  Container,
  Divider,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Navigate, useSearchParams } from "react-router-dom";
import { useState } from "react";

import { useAuth } from "../hooks/useAuth";

import WalletDialog from "../components/WalletDialog";

export default function SignInPage() {
  const [email, setEmail] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [params] = useSearchParams();
  const { isAuthenticated, isLoading, login } = useAuth();
  if (isLoading) {
    return (
      <Grid container justifyContent={"center"}>
        <CircularProgress />
      </Grid>
    );
  }
  if (isAuthenticated) {
    const next = params.get("next");
    return <Navigate to={next ?? "/redeem"} />;
  }

  return (
    <Grid container justifyContent={"center"}>
      <Grid item lg={6}>
        <Paper
          elevation={0}
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant={"h3"} align={"center"} paragraph={true}>
            Sign In
          </Typography>
          <Divider flexItem sx={{ mb: 2 }} />
          <TextField
            placeholder={"Email"}
            sx={{ mb: 1, width: "75%" }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            variant={"contained"}
            color={"primary"}
            size={"large"}
            sx={{ mb: 1, width: "75%" }}
            disabled={disabled || !email}
            onClick={() => login(email, params.get("next"))}
          >
            Next
          </Button>
          <Typography variant={"body2"} sx={{ maxWidth: "75%" }}>
            Note: You must enter the email address you used to buy with
            Crossmint.{" "}
          </Typography>
          <Typography paragraph={true}>OR</Typography>
          <Button
            onClick={() => setDialogOpen(true)}
            variant={"contained"}
            color={"primary"}
            size={"large"}
            sx={{ mb: 1, width: "75%" }}
          >
            Connect Wallet
          </Button>
        </Paper>
        <WalletDialog
          dialogOpen={dialogOpen}
          handleClose={() => setDialogOpen(false)}
        />
      </Grid>
    </Grid>
  );
}
