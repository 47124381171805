import { Box, ButtonBase } from '@mui/material';
import { ArrowUpward } from '@mui/icons-material';
import { useEffect, useState } from 'react';
function scrollToTop() {
  window.scrollTo({ top: 0, behavior: 'smooth' });
}

const useScroll = () => {
  const [scroll, setScroll] = useState(0);
  useEffect(() => {
    function handler() {
      setScroll(window.scrollY);
    }
    window.addEventListener('scroll', handler);
    return () => {
      window.removeEventListener('scroll', handler);
    };
  });
  return scroll;
};

export default function ScrollUp() {
  const scroll = useScroll();
  const LIMIT = window.innerHeight / 2;
  if (scroll < LIMIT) {
    return null;
  }
  return (
    <Box
      sx={{
        position: 'fixed',
        right: '30px',
        bottom: '30px',
        padding: 1,
        background: (t) => t.palette.primary.main,
        color: 'white',
      }}
    >
      <ButtonBase onClick={scrollToTop}>
        <ArrowUpward fontSize={'large'} color={'inherit'} />
      </ButtonBase>
    </Box>
  );
}
