import { useConfig } from "../common/core/config";
import { useContractRead } from "wagmi";
import { BigNumber } from "ethers";
export enum MintStatus {
  CLOSED,
  ALLOWLIST,
  PUBLIC,
}
export const useMintStatus = (contractAddress: string) => {
  const config = useConfig();
  const { data, isLoading } = useContractRead({
    addressOrName: contractAddress,
    contractInterface: config.abis[contractAddress],
    functionName: "mintStatus",
    chainId: config.chainId,
  });
  return {
    isLoading,
    mintStatus: data as unknown as MintStatus,
  };
};
