import { Typography, Paper, Box } from "@mui/material";
const styles = {
  width: "75vw",
  height: "75vh",
  position: "absolute",
  top: "12.5%",
  left: "12.5%",
  overflowY: "scroll",
};
export function TermsConditions() {
  return (
    <Paper sx={styles}>
      <Box p={10} sx={{ overflowY: "scroll" }}>
        <Typography variant={"body2"}>
          TERMS AND CONDITIONS FOR THE ONLINE SALE OF GOODS AND SERVICES
          <br />
          Last updated date: 7/21/22
          <br />
          COMPANY: Vera Bradley (referred to as “us”, “we”, or “our” as the
          context may require)
          <br />
          SERVICE: The World of Vera Bradley (the “Service”)
          <br />
          <br />
          PRIVACY POLICY: Our Privacy Policy is located at
          https://verabradley.com/pages/privacy-policy (the “Privacy Policy”).
          OUR LOCATION: We are located in Fort Wayne, IN (the “Jurisdiction”).
          1. THIS DOCUMENT CONTAINS VERY IMPORTANT INFORMATION REGARDING YOUR
          RIGHTS AND OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATIONS, AND
          EXCLUSIONS THAT MIGHT APPLY TO YOU. PLEASE READ IT CAREFULLY. BY
          PLACING AN ORDER FOR PRODUCTS OR SERVICES FROM THE SERVICE, YOU AFFIRM
          THAT YOU ARE OF LEGAL AGE TO ENTER INTO THIS AGREEMENT, AND YOU ACCEPT
          AND ARE BOUND BY THESE TERMS AND CONDITIONS. YOU MAY NOT ORDER OR
          OBTAIN PRODUCTS OR SERVICES FROM THE SERVICE IF YOU (A) DO NOT AGREE
          TO THESE TERMS, (B) ARE NOT THE OLDER OF (i) AT LEAST 18 YEARS OF AGE
          OR (ii) LEGAL AGE TO FORM A BINDING CONTRACT WITH US, OR (C) ARE
          PROHIBITED FROM ACCESSING OR USING THE SERVICE OR ANY OF THE SERVICE’S
          CONTENTS, GOODS OR SERVICES BY APPLICABLE LAW. These terms and
          conditions (these “Terms”) apply to the purchase and sale of products
          and services through the Service. These Terms are subject to change by
          us without prior written notice at any time, in our sole discretion.
          Any changes to the Terms will be in effect as of the “Last Updated
          Date” referenced herein. You should review these Terms prior to
          purchasing any product or services that are available through the
          Service. Your continued use of the Service after the “Last Updated
          Date” will constitute your acceptance of and agreement to such
          changes. These Terms are an integral part of the Terms of Use that
          apply generally to the use of our Service. You should also carefully
          review our Privacy Policy before placing an order for products or
          services through the Service (see Section 6). 2. Order Acceptance and
          Cancellation. You agree that your order is an offer to buy, under
          these Terms, all products and services listed in your order. All
          orders must be accepted by us or we will not be obligated to sell the
          products or services to you. We may choose not to accept orders at our
          sole discretion, even after we send you a confirmation email with your
          order number and details of the items you have ordered. 3. Prices and
          Payment Terms. (a) All prices, discounts, and promotions displayed by
          the Service are subject to change without notice. The price charged
          for a product or service will be the price in effect at the time the
          order is placed and will be set out in your order confirmation email.
          Price increases will only apply to orders placed after such changes.
          Posted prices do not include taxes. All such taxes and charges will be
          added to your merchandise total, and will be itemized in your shopping
          cart and in your order confirmation email. We strive to display
          accurate price information, however we may, on occasion, make
          inadvertent typographical errors, inaccuracies or omissions related to
          pricing and availability. We reserve the right to correct any errors,
          inaccuracies, or omissions at any time and to cancel any orders
          arising from such occurrences. (b) We may offer from time to time
          promotions on the Service that may affect pricing and that are
          governed by terms and conditions separate from these Terms. If there
          is a conflict between the terms for a promotion and these Terms, the
          promotion terms will govern. (c) Terms of payment are within our sole
          discretion and payment must be received by us before our acceptance of
          an order. You represent and warrant that (i) the method of payment
          information you supply to us is true, correct and complete, (ii) you
          are duly authorized to use such method of payment for the purchase,
          (iii) charges incurred by you will be honored, and (iv) you will pay
          charges incurred by you at the posted prices, including all applicable
          taxes, if any, regardless of the amount quoted on the Service at the
          time of your order. 4. Disclaimer of Warranties. ALL PRODUCTS AND
          SERVICES OFFERED ON THIS SERVICE ARE PROVIDED “AS IS” WITHOUT ANY
          WARRANTY WHATSOEVER, INCLUDING, WITHOUT LIMITATION, ANY (A) WARRANTY
          OF MERCHANTABILITY; (B) WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE;
          OR (C) WARRANTY AGAINST INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS
          OF A THIRD PARTY; WHETHER EXPRESS OR IMPLIED BY LAW, COURSE OF
          DEALING, COURSE OF PERFORMANCE, USAGE OF TRADE, OR OTHERWISE. SOME
          JURISDICTIONS LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER
          WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO YOU. 5. Limitation
          of Liability. IN NO EVENT SHALL WE BE LIABLE TO YOU OR ANY THIRD PARTY
          FOR CONSEQUENTIAL, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE
          OR ENHANCED DAMAGES, LOST PROFITS OR REVENUES OR DIMINUTION IN VALUE,
          ARISING OUT OF, OR RELATING TO, AND/OR IN CONNECTION WITH ANY BREACH
          OF THESE TERMS, REGARDLESS OF (A) WHETHER SUCH DAMAGES WERE
          FORESEEABLE, (B) WHETHER OR NOT WE WERE ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES AND (C) THE LEGAL OR EQUITABLE THEORY (CONTRACT, TORT OR
          OTHERWISE) UPON WHICH THE CLAIM IS BASED. OUR SOLE AND ENTIRE MAXIMUM
          LIABILITY, FOR ANY REASON, AND YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY
          CAUSE WHATSOEVER, SHALL BE LIMITED TO THE ACTUAL AMOUNT PAID BY YOU
          FOR THE PRODUCTS AND SERVICES YOU HAVE ORDERED THROUGH OUR SERVICE.
          The limitation of liability set forth above shall: (i) only apply to
          the extent permitted by law and (ii) not apply to (A) liability
          resulting from our gross negligence or willful misconduct and (B)
          death or bodily injury resulting from our acts or omissions. 6.
          Privacy. Our Privacy Policy governs the processing of all personal
          data collected from you in connection with your purchase of products
          or services through the Service. 7. Force Majeure. We will not be
          liable or responsible to you, nor be deemed to have defaulted or
          breached these Terms, for any failure or delay in our performance
          under these Terms when and to the extent such failure or delay is
          caused by or results from acts or circumstances beyond our reasonable
          control, including, without limitation, acts of God, flood, fire,
          earthquake, explosion, governmental actions, war, invasion or
          hostilities (whether war is declared or not), terrorist threats or
          acts, riot or other civil unrest, national emergency, revolution,
          insurrection, epidemic, lockouts, strikes or other labor disputes
          (whether or not relating to our workforce), or restraints or delays
          affecting carriers or inability or delay in obtaining supplies of
          adequate or suitable materials, materials or telecommunication
          breakdown or power outage. 8. Governing Law and Jurisdiction. This
          Service is operated from the Jurisdiction. All matters arising out of
          or relating to these Terms are governed by and construed in accordance
          with the internal laws of the Jurisdiction without giving effect to
          any choice or conflict of law provision or rule (whether of the
          Jurisdiction or any other jurisdiction) that would cause the
          application of the laws of any jurisdiction other than those of the
          Jurisdiction. 9. Assignment. You will not assign any of your rights or
          delegate any of your obligations under these Terms without our prior
          written consent. Any purported assignment or delegation in violation
          of this Section 9 is null and void. No assignment or delegation
          relieves you of any of your obligations under these Terms. 10. No
          Waivers. The failure by us to enforce any right or provision of these
          Terms will not constitute a waiver of future enforcement of that right
          or provision. The waiver of any right or provision will be effective
          only if in writing and signed by our duly authorized representative.
          11. No Third-Party Beneficiaries. These Terms do not and are not
          intended to confer any rights or remedies upon any person other than
          you. 12. Notices. (a) To You. We may provide any notice to you under
          these Terms by: (i) sending a message to the email address you provide
          or (ii) by posting to the Service. Notices sent by email will be
          effective when we send the email and notices we provide by posting
          will be effective upon posting. It is your responsibility to keep your
          email address current. (b) To Us. To give us notice under these Terms,
          you must contact us as follows by email to the Contact Email. We may
          update the email address or address for notices to us by posting a
          notice on the Service. Notices provided by personal delivery will be
          effective immediately. Notices provided by facsimile transmission or
          overnight courier will be effective one business day after they are
          sent. Notices provided by registered or certified mail will be
          effective three business days after they are sent. 13. Severability.
          If any provision of these Terms is invalid, illegal, void or
          unenforceable, then that provision will be deemed severed from these
          Terms and will not affect the validity or enforceability of the
          remaining provisions of these Terms. 14. Entire Agreement. Our order
          confirmation, these Terms, our Terms of Use and our Privacy Policy
          will be deemed the final and integrated agreement between you and us
          on the matters contained in these Terms. Terms of Service LAST UPDATED
          DATE: 7/21/22 COMPANY: Vera Bradley (the “Company”) SERVICE: The World
          of Vera Bradley (“the Service”) PRIVACY POLICY: The Company’s Privacy
          Policy is located at https://verabradley.com/pages/privacy-policy (the
          “Privacy Policy”) 1. Introduction The Company’s Service is a platform
          for the Company’s users like you (“you”, and “your”) that enables you
          to access Company-related content alongside tokens, rewards, NFTs and
          other features. The Service is provided by Minotaur Labs, Inc. (“we”,
          “us” and “our”), on behalf of the Company. These Terms of Service
          (“Terms”) and the associated Privacy Policy apply to your use of the
          Service. The Company is also the Data Controller for the purposes of
          any personal data you provide. For information on how we and the
          Company (as “Data Controller”) use your personal data, please see the
          associated Privacy Policy. You must review and accept these Terms
          before you can use the Service. Unless otherwise specified in the
          Service details on the applicable Service Store, to use the Service
          you must be 18 or older (or be 13 or older and have your parent or
          guardian’s consent). We license use of the Service to you on the basis
          of these Terms. We do not sell the Service to you. We remain owners of
          the Service at all times, but please note that the content provided by
          the Company via the Service is owned by and remains the responsibility
          of the Company. 2. Changes We may occasionally make changes to the
          Service or these Terms. If we do, we’ll notify you either by updating
          the Effective Date of these Terms listed above and via the Service.
          You must accept these changes in order to continue using the Service.
          You should stop using the Service if you disagree with any changes to
          the Service or these Terms. From time to time, we may issue updates to
          the Service. Depending on the update, you may not be able to use the
          Service until you have activated the latest version of the Service and
          reviewed and accepted any new terms. 3. Accessing the Service and the
          content on the Service You may access the Service by downloading and
          installing the Service to your device from your device’s integrated
          app store (“Service Store”). Although we are working to ensure that
          the Service is compatible across various devices, we cannot guarantee
          that the Service will work with all devices. You should check the
          minimum requirements and specifications (including memory space and
          operating system) as set out in the Service details on the applicable
          Service Store before downloading the Service. We will endeavor to
          support the two most recent versions of the operating systems
          available in the market (for example, if iOS 11 is the current
          version, we will aim to support iOS 10 and iOS 11). To download the
          Service, you will need a valid Service Store account (as Applicable to
          your device). It is your responsibility to ensure that you are able to
          comply with the relevant system requirements as described above. We
          accept no responsibility for any lack of functionality that is due to
          your equipment (including your device, internet connection, operating
          system or settings and software). Access to parts or all of the
          Service and any or all content on the Service may be restricted from
          time to time to allow for repairs, maintenance or updating. It is your
          responsibility to pay for all costs and expenses (including all
          telephone call or line charges or internet service data access) that
          you may incur using the Service. Your use of the Service and enjoyment
          of its features and content hosted or made available through the
          Service may vary in functionality, availability and quality depending
          on the type of device and operating system and any restrictions
          imposed by our content providers. 4. Your Use of the Service In
          consideration of you complying with these Terms, we grant you a
          non-transferable, non-exclusive, revocable license to use the Service
          and to view the content contained on the Service for your personal,
          non-commercial use, subject to and in accordance with these Terms, the
          Privacy Policy and the applicable Service rules, which are
          incorporated into these Terms by reference. You agree: a. that you
          will not use the Service for any illegal purpose or in any way that
          interrupts, damages or impairs the service provided by the Service; b.
          that you will not access or attempt to access the accounts of other
          users of the Service; c. that you will not impersonate any person, or
          misrepresent your identity or affiliation with any person; d. not to
          post or transmit through the Service any content which is or could
          reasonably be viewed as: i. hate speech, obscene, harassing,
          threatening, pornographic, abusive or defamatory to an individual or
          group of individuals on the basis of religious belief, race, gender,
          age, disability or otherwise; ii. inciting violence, or containing
          nudity or graphic or gratuitous violence; iii. an unauthorized
          commercial communication of any kind (including, without limitation,
          spam); iv. fraudulent, inaccurate or misleading, or is otherwise
          objectionable content of any kind; v. infringing or violating someone
          else’s rights or otherwise violates the law; vi. identifying any
          person without their consent or disclosing anyone else’s personal
          contact details or invading their privacy, or vii. containing software
          viruses or any other computer code, files or programs designed to
          interrupt, destroy or limit the functionality of the Service, or any
          computer software or hardware or telecommunications equipment. e. to
          refrain from doing anything which is defamatory, offensive, damaging
          or which we believe might damage our reputation, or that of the
          Service, a provider of services accessed through the Service, or the
          Company; f. not to copy the Service or any content on the Service
          except where such copying is incidental to the normal non-commercial
          use of the Service, or where it is necessary for the purpose of
          back-up or operational security; g. not to make alterations to, or
          modifications of, the whole or any part of the Service or any content
          on the Service, or permit the Service or any part of it to be combined
          with, or become incorporated in, any other programs; h. not to
          infringe our intellectual property or the Company’s or our other
          licensors’ intellectual property in relation to your use of the
          Service; i. that you are solely responsible for your interactions with
          other users and users through the Service; j. That you agree to
          compensate and defend us fully against any claims or legal proceedings
          brought against us by any person as a result of your breach of these
          Terms; k. to keep your password secure at all times and not to
          disclose your password to any other person; l. not to allow any other
          person to use or access your account; and m. to comply with all laws
          applicable to you. 5. Termination We may terminate these Terms and
          close your account at any time without notice if we cease to provide
          the Service. In addition to our right to remove any content from the
          Service, we reserve the right to suspend, restrict or terminate your
          access to the Service at any time without notice if we have reasonable
          grounds to believe you have breached any of these Terms. We also
          reserve the right to disable your account at our reasonable
          discretion. Suspension, restriction or termination of your access to
          the Service will not limit our right to take any other action against
          you that we consider appropriate. You may close your account and
          terminate your agreement with us at any time by emailing us at the
          Contact Email. 6. Intellectual Property You acknowledge that the
          Service, the content provided on the Service and all copyrights,
          patents, trademarks, trade secrets, source code, object code and other
          intellectual property associated therewith are, and shall remain, the
          property of us or our licensors. You are not granted any intellectual
          property rights in or to the Service or the content on the Service
          except as expressly set out in these Terms. You are not authorized to
          use our or the Company’s logos or trademarks or trade names (whether
          registered or unregistered) in any manner. You may only use the
          Service for personal, non-commercial purposes. 7. Licensing The
          service and content provided through the Service and the Service
          itself are our property or the property of our licensors. You are
          strictly only entitled to use the Service, and consume content made
          available through the Service, in accordance with these Terms. The
          Service and any other software made available to you via the Service
          is licensed (not sold) to you, meaning that we or our licensors
          continue to own all copies of the Service and other software when it
          is installed on your device. We may freely assign these Terms or any
          part of them, but you may not assign your rights under these Terms, or
          any part of them, nor may you sub-license your rights under these
          Terms, to any third party. These terms do not grant you any rights to
          use any of our, our licensors’ or the Company’s intellectual property,
          such as trademarks, domain names, logos or other branded features,
          which belong to us and our licensors respectively. 8. Personal Data
          For information on how we and the Company (as Data Controller) use
          your personal data, please see the associated Privacy Policy. 9. Our
          Legal Obligations Nothing in these Terms excludes or limits liability
          for death or personal injury caused by gross negligence or fraud. We
          are not responsible for: a. losses, damage, costs or expenses not
          caused by our breach of these terms; b. the actions or omissions of
          Company or our licensors introduced to you through the Service; c. the
          actions or omissions of other users of the Service; d. any indirect or
          consequential loss or damage which means a loss suffered by you which
          is a side effect of the main loss or damage, whether or not we could
          have anticipated that type of loss arising when you agreed to these
          terms; or e. any harm, loss or damage suffered by you or anyone else
          if the Service is interrupted, suspended or otherwise not provided to
          you or if we do not comply with these terms because of events beyond
          our control (for example, industrial disputes, technical difficulties,
          failure of or delays in communications networks, acts of terrorism or
          power failure). For any loss or damage suffered by you or anyone else
          that may arise from use of the Service and which is not otherwise
          excluded under this section 9, to the extent permitted by law our
          liability is limited to $100. 10. Disclaimer and Technical Limitations
          The Service and any service provided through it is provided without
          express or implied warranty or condition of any kind, on an “as-is”
          basis, subject to applicable law. You agree that you must evaluate,
          and that you bear all risks associated with, the use of the Service,
          including without limitation, any reliance on the accuracy,
          completeness or usefulness of any materials available through the
          Service. We have no liability to you for any loss of profit, loss of
          business, business interruption or loss of business opportunity. You
          acknowledge and accept that your access to the Service is dependent on
          connectivity over communications networks and facilities that are
          outside of our operation and/or control and that your use of the
          Service may be subject to limitations, delays and other problems
          inherent in the use of such networks and facilities. We disclaim all
          warranties with respect to the Service including, but not limited to,
          the warranties of non-infringement and title. We give no warranty that
          your use of the Service will be uninterrupted or error free, that the
          information obtained from the Service will be accurate, complete,
          current, or reliable, that the quality of the Service will be
          satisfactory to you, or that errors or defects will be corrected. You
          acknowledge and accept that we are not responsible for any loss or
          damage of any kind that you may suffer as a result of any interruption
          or delay to your access to the Service, or as a result of any failure
          or error in the transfer of data over those networks and facilities in
          connection with your use of the Service. 12. Third-party sites or
          services The Service may include and link to features, websites and
          services (such as the Company’s own website and social applications
          like Twitter, Facebook, Pinterest, YouTube and Instagram) that are
          provided by a third party. We do not control such third-party sites or
          services and are not responsible for the content of such sites or
          services. Our inclusion of links does not imply any endorsement or
          association with their operators. The terms applicable to use of that
          third-party service will apply and we will not be responsible for
          anything that is done or not done by you or the third-party service
          provider in connection with your use of that third-party service. We
          recommend that you check the applicable third-party service’s terms
          and conditions of use and sale before using such third-party service
          or purchasing any products or services. 13. Disputes Unless otherwise
          required by applicable law, any disputes in connection with these
          Terms will be governed by and interpreted in accordance with the laws
          of the state of Delaware, United States of America and the courts of
          Delaware will have exclusive jurisdiction to hear any claims made in
          relation to these Terms. If the court in your country will not apply
          the laws of the state of Delaware, or if the courts in your country
          will not permit you to consent to the jurisdiction of the courts in
          the state of Delaware, then your local law and jurisdiction will apply
          to such disputes related to these Terms. 14. Other Important Legal
          Terms You may not transfer your rights or obligations under these
          Terms to anyone else. If you breach these Terms and we take no action
          against you, or if we delay in doing so, that will not mean that we
          have waived our rights against you and we will still be entitled to
          enforce our rights and remedies against you in relation to that breach
          and to use our rights and remedies in any other situation where you
          breach these Terms. If any part of these Terms is disallowed or found
          to be ineffective by any court or regulator, the remainder of the
          Terms will continue to govern each of our respective obligations.
          These Terms are not intended to give rights to anyone except you and
          us. Except as otherwise expressly stated, these Terms and our Privacy
          Policy contain the entire agreement between us, the Company and you
          relating to use of the Service and supersede any previous agreements,
          arrangements, undertakings or proposals, written or oral, between us,
          the Company and you relating to use of the Service. Privacy Policy
          LAST UPDATED DATE: 7/21/22 COMPANY: Vera Bradley (the “Company”)
          SERVICE: The World of Vera Bradley (“the Service”) TERMS OF SERVICE:
          The Company’s Terms of Service are located at
          https://verabradley.com/pages/terms-of-use (the “Terms of Service”)
          OUR LOCATION: We are located in Fort Worth, IN (the “Jurisdiction”).
          Thanks for joining the Service. In order for you to participate we
          will need your consent to this Privacy Policy and the associated Terms
          of Service. Thank you. This Privacy Policy has been created to help
          you understand the personal data which we may collect, how we intend
          to securely process and store that data, and the rights you have in
          relation to your personal data. Along with the Terms of Service, this
          Privacy Policy makes up our agreement with you as a user of our
          Service and we need to ensure you understand that agreement. Summary
          Privacy Policy ● Data Controller: Company ● We will only collect
          personal information that you have voluntarily decided to provide to
          us or we have recorded from your usage of the Service. ● Depending on
          how you choose to interact with us we may collect your name, email,
          phone number, IP address or any other contact details or content which
          you have provided to us. ● We will use this personal information: ○ to
          provide you with the full features of the Service; and ○ to analyze,
          improve and promote the Service and its content. ● We will always ask
          you before processing your personal data in any other ways. ● We will
          use an anonymized version of your personal data, from which you cannot
          be identified, to prepare statistics so we can learn and improve our
          service. For example, how many users login each day, how many
          transactions are sent, etc. We may also share such anonymized
          information with third parties. ● We will only share your personal
          information with our declared data processors. ● We may transfer your
          data to authorized data processors but will make sure that there are
          appropriate safeguards in place. By voluntarily submitting your
          personal data you consent to these transfers. ● We will never sell,
          rent or give away your identifiable personal information to other
          third parties. ● We will only keep your information for as long as
          needed to answer your query or as required by applicable legislation
          or regulations. ● We will use appropriate technical and organizational
          measures to ensure the safety, security and accuracy of your personal
          data. ● You have important rights in relation to the use of your
          personal data, listed in Section 9. ● We do not knowingly request or
          store sensitive or children’s information. ● You should send an email
          to the Contact Email if you have any questions about this Privacy
          Policy or the collection, processing and storage of your personal
          data. Full Privacy Policy 1. Introduction This Privacy Policy will
          help you to understand what information we collect and process using
          the Service, and the choices and rights you have in connection with
          your personal information. In this Privacy Policy when we refer to
          “we”, “us” or “our” we are referring to the Company the “Data
          Controller” for this Service. The Data Controller is responsible for
          determining the processing purposes of your personal data, and the
          content and related services or features which are made available to
          you from using this Service. 2. What is the Purpose of this Service?
          The purpose of this Service is to provide a rewards program to you and
          other users. 3. Where do we collect personal data about you from? We
          may collect personal data about you from the following sources: ●
          Directly from you. This is information you have voluntarily provided
          when entering your personal details on the Service. ● Through publicly
          available sources such as Facebook, Twitter or LinkedIn. We do not
          collect personal data about you: ● From an agent/third party acting on
          your behalf. 4. What Information Do We Collect and Why? We will only
          ever collect the information we need to enable us to undertake the
          specific information processing activities noted later in this
          section. We collect and process two distinct kinds of information: ●
          non-personal information such as the pages you have accessed, helping
          us to determine how many people use our Service, how many people visit
          on a regular basis, and how popular each of our pages or features are.
          This information doesn’t tell us anything about who you are or where
          you live. It simply allows us to monitor and improve our service. ●
          personal information such as your IP address, email address, username,
          password, approximate location, transactions you complete within or
          connected to the Service and any optional information you may choose
          to provide to us as part of your experience within the Service. Should
          you decide to register we ask for the following information: ● Email
          address - we use this to facilitate your registration for the Service
          and to send Service-related communications such as resetting your
          password, verifying your email address or offering you opportunities
          to participate in other parts of the Service. We will not send you any
          marketing or third-party messages if you have explicitly requested
          that we do not do so. ● Your name/username - You do not need to use
          your real name, though we generally encourage it. ● A photo - this may
          be optional as part of the Service. ● A password - we store this in a
          secure one-way encrypted system. If you forget your password, you may
          request that it be reset, and an email will be sent to you with
          instructions on how to do so. Once registration is complete, and you
          have verified your email you may, if you choose, use the Services. As
          you use the Services, we will keep track of your reward activity. We
          also use this data in an aggregated, anonymized form to understand how
          popular the Service and its different features are so that we can
          improve the Service. This aggregated, anonymized data may be shared
          with third parties. We record the last IP address from which you
          accessed the Service so that we can protect the Service from malicious
          access. As part of this we may look up the approximate location of the
          IP address such as country and city. Your decision to disclose your
          personal information to us is entirely voluntary. If you do not
          provide the personal data necessary or withdraw your consent for the
          processing of your personal data, you may not be able to access or use
          the Services. We will only retain your personal information for as
          long as you are a registered user of the Services. We comply with all
          legislative and regulatory information retention requirements and will
          securely and permanently delete your personal information when there
          is (a) no justification for its further retention, or (b) you have
          asked us to delete it. We will not use your identifiable personal
          information for any other purposes. We will not share your
          identifiable personal information with any other party, other than the
          declared Data Processors recorded in Section 11. 5. What information
          do we share with third parties? In addition to the Subprocessors
          listed in Section 11, we may provide anonymized, aggregated
          information, statistics, trends and insights about the use of the
          Services to third parties, but we do not sell any of your personal
          information to anyone. Examples of such third parties include the
          following: A. Analytics and Measurement Services We may engage third
          parties to help us and our partners understand how our users use the
          Services. We may share anonymized, aggregated information with such
          parties to enable them to generate reports and analyses of the
          Services. B. Advertisers We may provide advertisers with anonymized,
          aggregated information about our users and their use of the Services
          to enable advertisers to allow them to better understand their
          audience. We do not provide advertisers with any information that
          could identify you or any individual user. C. Commercial Partners We
          may provide our commercial partners with anonymized, aggregated
          information to allow them to integrate their products or services with
          the Services and to enable us to expand our products and services. 6.
          What legal basis do we have for using your personal data? The legal
          basis we have for processing your data is based around the consent you
          have voluntarily provided us. 7. Sensitive Personal Information This
          Service does not knowingly collect or process any sensitive personal
          information (e.g., racial or ethnic origin, political opinions, or
          religious or philosophical beliefs) unless you have chosen to
          voluntarily disclose and share such information during your use of the
          Service. 8. Children’s Personal Data This Service, and any services
          available from it, are not directed to users under the age of 18 (save
          where any users between 13 - 18 years old have obtained prior parental
          or guardian consent). If you learn that a user under the age of 18 has
          provided us with their personal information without having parental or
          guardian consent, please contact us by sending an email to the Contact
          Email immediately so we can take appropriate action. 9. User Data
          Rights Depending on your location, you may have several rights
          connected to the provision of your personal information to us from
          using the Service. A. The right to be informed You have the right to
          be provided with clear, transparent and easily understandable
          information about how we use your information and your rights. This is
          why we’re providing you with the information in this Privacy Policy.
          B. The right of access You may have the right to obtain access to your
          personal information (if we’re processing it), and certain other
          information such as the reasons why we are processing or storing it.
          This is so you’re aware and can check that we’re using your personal
          information in accordance with data protection legislation and your
          agreement. C. The right to rectification You are entitled to request
          that your personal information is promptly corrected if it’s
          identified as being inaccurate or incomplete. D. The right to erasure
          This is also known as ‘the right to be forgotten’ and, in simple
          terms, enables you to request the deletion or removal of your
          information under certain circumstances where there’s no compelling
          reason for us to keep using it. This is not a general right to
          erasure; there are exceptions. E. The right to restrict processing You
          have rights to ‘block’ or suppress further use of your personal
          information. When processing is restricted, we can still store your
          information but may not be able to process it further. We maintain
          lists of people who have asked for further use of their personal
          information to be ‘blocked’ to make sure the restriction is respected
          in future. F. The right to object to processing You have the right to
          object to certain types of personal data processing, including
          processing for direct marketing activities. G. The right to lodge a
          complaint Depending on your location, you may have the right to lodge
          a complaint about the way we have handled or processed your personal
          data with the applicable data protection regulator. H. The right to
          withdraw consent If you have given your consent to anything we do with
          your personal data, you have the right to vary or withdraw your
          consent at any time (although if you do so, it does not mean that
          anything we have done with your personal data with your consent up to
          that point is unlawful). This includes your right to withdraw your
          consent to us using your personal data for marketing purposes. For any
          of the above rights, we usually act on validated requests and provide
          the requested information or activity free of charge, but by law we
          are allowed to charge a reasonable fee to cover our administrative
          costs of providing the information for baseless or excessive/repeated
          requests or further copies of the same information. Alternatively,
          there are reasons why we may be entitled to refuse to act on the
          request. Please consider your request responsibly before submitting
          it. We’ll respond as soon as we can. Generally, this will be within
          one month from when we receive your validated request but, if the
          request is going to take longer to deal with, we will let you know. To
          contact us please send an email to the Contact Email. If we do not
          address your request or fail to provide you with a valid reason why we
          are unable to do so, you may have the right to contact the applicable
          data protection regulator. For more information about the applicable
          data protection regulator, please see Section 15. 10. Personal Data
          Breach Reporting You have the right to be promptly informed by us of
          any personal data loss, theft or compromise arising directly or
          indirectly from the Service, and any supporting systems or declared
          Data Processors (see Section 10) involved with delivering, supporting,
          maintaining, monitoring or improving the Service. Similarly, we may be
          required to notify the applicable data protection regulator. As a user
          of the Service, you have a responsibility to safeguard and manage your
          Service login credentials securely. This requires you to ensure that
          they are changed frequently, of sufficient strength and complexity,
          different from any other passwords you may use, and not recorded in a
          format which could be accessed or guessed by others. If you suspect
          that your credentials have been compromised, you should notify us
          immediately by sending an email to the Contact Email. We will not be
          liable for any personal information loss, theft or compromise where
          this can be attributed to your failure to secure your Service login
          credentials. 11. Declaration of Personal Data Subprocessors To make an
          informed decision on whether to provide your personal data to us when
          using this Service, we need to make you aware of the organizations
          that act as Data Subprocessors for us, helping in the provision of the
          Service and its functionality. These partners are as follows: ●
          Minotaur Labs, Inc.: provider of the Service technology platform,
          based in the United States. Minotaur Labs, Inc. (“Minotaur”) is a
          corporation registered with the state of Delaware. Below is a list of
          subprocessors that Minotaur might utilize. This list may be updated
          from time to time. ● MailChimp: Used to send administration of service
          emails such as email verification, password reset and welcome email;
          and other potential marketing messages where you have given your
          explicit consent for us to do so. Based in the United States.
          MailChimp complies with the EU-US Privacy Shield Framework, as set
          forth by the US Department of Commerce, covering the collection, use
          and retention of personal data transferred from the European Union to
          the United States. ● Google Analytics: Used to provide analytics to
          understand how the Service is used and help provide actionable
          insights for improvements. Google, including Google Inc. and its
          wholly-owned US subsidiaries, comply with the EU-US Privacy Shield
          Framework, as set forth by the US Department of Commerce, covering the
          collection, use and retention of personal data transferred from the
          European Union to the United States. ● Google G Suite: Used as email
          system, so any emails you send to support will be handled by G Suite.
          Google, including Google Inc. and its wholly-owned US subsidiaries,
          comply with the EU-US Privacy Shield Framework, as set forth by the US
          Department of Commerce, covering the collection, use and retention of
          personal data transferred from the European Union to the United
          States. ● Crashlytics (Part of Google): Used to provide actionable
          insights and analytics on crash reporting. Part of Fabric, acquired by
          Google’s Developer Products Group. Crashlytics complies with the EU-US
          Privacy Shield Framework, as set forth by the US Department of
          Commerce, covering the collection, use and retention of personal data
          transferred from the European Union to the United States. ● Amazon Web
          Services: Hosting services. AWS complies with the EU-US Privacy Shield
          Framework, as set forth by the US Department of Commerce, covering the
          collection, use and retention of personal data transferred from the
          European Union to the United States. ● Heroku (Subsidiary of
          Salesforce): Hosting services. Heroku complies with the EU-US Privacy
          Shield Framework, as set forth by the US Department of Commerce,
          covering the collection, use and retention of personal data
          transferred from the European Union to the United States. 12.
          International Transfers of Personal Data We are located in the
          Jurisdiction. Any personal data you provide to us will be transferred
          to that country. At least one of our Data Subprocessors is located in
          the United States, so, if you are located outside the United States,
          your personal information will be transferred to and processed in the
          United States. As we have described above, to be able to provide you
          with the Services, we may transfer your personal data to subprocessors
          in other countries where the privacy laws may be different from those
          in your home country. By voluntarily submitting your personal data to
          us you consent to these international transfers. If you later wish to
          withdraw your consent, please contact the Data Controller by sending
          an email to the Contact Email. 13. Use of Cookies Cookies are small
          text files sent by websites to your web browser and sent back to them
          each time you access or use the site and may be necessary for the site
          to function. They are unique to you or your web browser and may
          contain identifiable personal information as well as technical
          information (e.g., your device manufacturer and model, screen
          resolution, internet service provider, browser, and geo-location
          data). Session-based cookies last only while your browser is open and
          are automatically deleted when you close the browser. Persistent
          cookies last until you or your browser delete them, or until they
          expire. When using this Service, you should be aware that the element
          of the Service on your device does not use cookies. We do maintain
          recognition of login, but this is not managed by cookies. Third-party
          websites which we, or other users, may link to might however include
          cookies. These are outside of our control and we cannot guarantee
          their behavior. These sites may use both session-based and persistent
          cookies, dependent upon the functionality in those sites. However,
          when posts are shared from the Service into social media, the webpages
          in the Service that support this feature do use cookies to anonymously
          gather metrics on which pages are the most popular. 14. Email Opt-In
          By purchasing an NFT and providing your email address, you are
          agreeing to opt-in to receiving Vera Bradley emails from time to time
          which the user may opt-out from if a user so chooses. 15. Changes to
          this Privacy Policy We may change this Privacy Policy from time to
          time, and if we do so, you will be notified at your next available
          interaction with the Service, at which time you will be provided with
          the updated Privacy Policy to review and consent to before you are
          able to continue using the Service.
        </Typography>
      </Box>
    </Paper>
  );
}
