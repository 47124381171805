import React from "react";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import Home from "./Home";
import Mint from "./Mint";
import WalletDialog from "./components/WalletDialog";
import Shell from "./components/Shell";
import {
  Routes,
  BrowserRouter as Router,
  Route,
  Navigate,
} from "react-router-dom";
import { ScrollingProvider } from "react-scroll-section";

import {
  WagmiConfig,
  createClient,
  configureChains,
  defaultChains,
} from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";

import { ConfigProvider } from "./common/core/config";

import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { MintSuccess } from "./pages/MintSuccess";
import { buildConfig } from "./common/build-config";
import SignInPage from "./pages/SignIn";
import RedeemPage from "./pages/RedeemPage";
import Callback from "./pages/Callback";
import { DefaultLayoutPage } from "./pages/DefaultLayout";
import { RedemptionSuccessful } from "./pages/RedemptionSuccessful";
import AlreadyRedeemed from "./pages/AlreadyRedeemed";
import { MagicProvider } from "./common/auth/MagicContext";
import NoTokens from "./pages/NoTokens";
import Mint1982Page from "./pages/Mint1982Page";
import { MintSuccess1982 } from "./pages/MintSucess1982";

const coinbaseWalletConnector = new CoinbaseWalletConnector({
  options: {
    appName: "vera",
  },
});
const metaMaskConnector = new MetaMaskConnector();
const { provider } = configureChains(defaultChains, [
  alchemyProvider({
    apiKey: buildConfig.alchemyKey,
    priority: 0,
  }),
  publicProvider({ priority: 1 }),
]);

const client = createClient({
  autoConnect: true,
  connectors: [metaMaskConnector, coinbaseWalletConnector],
  provider,
  persister: null,
});

function App() {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  return (
    <ConfigProvider>
      <WagmiConfig client={client}>
        <ThemeProvider theme={theme}>
          <ScrollingProvider offset={0}>
            <MagicProvider>
              <WalletDialog
                dialogOpen={dialogOpen}
                handleClose={() => setDialogOpen(false)}
              />
              <Router>
                <Routes>
                  <Route
                    path="/"
                    element={<Shell handleOpen={() => setDialogOpen(true)} />}
                  >
                    <Route index element={<Home />} />
                    <Route path="mint-heritage-pass" element={<Mint />} />
                    <Route
                      path="mint-1982-collection"
                      element={<Mint1982Page />}
                    />
                    <Route path="/" element={<DefaultLayoutPage />}>
                      <Route path="callback" element={<Callback />} />
                      <Route path="redeem" element={<RedeemPage />} />
                      <Route path="sign-in" element={<SignInPage />} />
                      <Route path="mint-successful" element={<MintSuccess />} />
                      <Route
                        path="1982-mint-successful"
                        element={<MintSuccess1982 />}
                      />

                      <Route
                        path="redemption-successful"
                        element={<RedemptionSuccessful />}
                      />
                      <Route
                        path="already-redeemed"
                        element={<AlreadyRedeemed />}
                      />
                      <Route path="no-tokens" element={<NoTokens />} />
                    </Route>
                    <Route path="*" element={<Navigate to={"/"} />} />
                  </Route>
                </Routes>
              </Router>
            </MagicProvider>
          </ScrollingProvider>
        </ThemeProvider>
      </WagmiConfig>
    </ConfigProvider>
  );
}

export default App;
