import { createTheme } from "@mui/material";
export const theme = createTheme({
  palette: {
    primary: {
      main: "#e1228e",
    },
    secondary: {
      main: "#2e2f75",
    },
    text: {
      primary: "#333",
    },
    grey: {
      100: "#f3f3f3",
    },
  },
  typography: {
    allVariants: {},
    fontFamily: "Avenir-book, sans-serif",
    body2: {
      fontSize: "15px",
      fontFamily: "Avenir-Book",
      lineHeight: "20px",
      "@media (max-width:600px)": {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    subtitle1: {
      fontSize: "13px",
      textAlign: "left",
      lineHeight: "20px",
      fontFamily: "Avenir-medium",
      "@media (max-width:600px)": {
        fontSize: "11px",
      },
    },
    h3: {
      fontSize: "26px",
      lineHeight: "32px",
      fontFamily: "Avenir-medium",
      "@media (max-width:600px)": {
        fontSize: "21px",
      },
    },
    h5: {
      fontSize: "26px",
      fontFamily: "Avenir-medium",
      "@media (max-width:600px)": {
        fontSize: "22px",
      },
    },
    h1: {
      fontSize: "52px",
      lineHeight: "72px",
      fontFamily: "Avenir-Book",
      "@media (max-width:600px)": {
        fontSize: "46px",
        lineHeight: "64px",
      },
    },
    h4: {
      fontSize: "20px",
      lineHeight: "28px",
      fontFamily: "Avenir-Heavy",
      "@media (max-width:600px)": {
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
    h2: {
      fontSize: "38px",
      lineHeight: "48px",
      fontFamily: "Avenir-Book",
      "@media (max-width:600px)": {
        fontSize: "31px",
        lineHeight: "40 px",
      },
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "p",
          subtitle2: "p",
          body1: "p",
          body2: "p",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: "rgba(187, 187, 187, 0.5)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        notchedOutline: {
          borderColor: "black",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: { textTransform: "none" },
        root: {
          borderRadius: 0,
          boxShadow: "none",
        },
      },
    },
  },
});
// @ts-ignore
window.theme = theme;
