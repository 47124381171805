import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

import { BigNumber, ethers } from "ethers";
import { CrossmintConfig, useConfig } from "./core/config";

type CrossmintProps = {
  ethPrice: BigNumber;
  quantity: number;
  configKey: string;
};
export function Crossmint({ quantity, configKey, ethPrice }: CrossmintProps) {
  const config = useConfig();
  // @ts-ignore
  const collectionInfo: CrossmintConfig = config[configKey];
  return (
    <CrossmintPayButton
      {...collectionInfo}
      mintConfig={{
        ...collectionInfo.mintConfig,
        totalPrice: ethers.utils.formatEther(ethPrice.mul(quantity)),

        quantity,
      }}
    />
  );
}
