import { questionContent } from "./question-content";
import { ButtonBase, Collapse, Stack, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CircleUp from "@mui/icons-material/ArrowCircleUp";
import { useState, Fragment } from "react";

export default function Faq() {
  return (
    <Stack alignItems={"flex-start"}>
      {questionContent.map(({ title, questions }) => {
        return (
          <Fragment key={title}>
            <Typography variant={"h3"} paragraph={true}>
              {title}
            </Typography>
            {questions.map(({ title, answer }) => (
              <Question key={title} title={title} answer={answer} />
            ))}
          </Fragment>
        );
      })}
    </Stack>
  );
}

function Question({
  title,
  answer,
}: {
  title: string;
  answer: string | React.ReactNode;
}) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ButtonBase sx={{ pt: 1 }} onClick={() => setOpen((o) => !o)}>
        {open ? (
          <CircleUp sx={{ margin: "-18px 5px 0 0" }} />
        ) : (
          <AddCircleOutlineIcon sx={{ margin: "-18px 5px 0 0" }} />
        )}
        <Typography key={title} paragraph={true} variant={"subtitle1"}>
          {title}
        </Typography>
      </ButtonBase>
      <Collapse in={open}>
        <Typography paragraph={true} variant={"body2"}>
          {answer}
        </Typography>
      </Collapse>
    </>
  );
}
