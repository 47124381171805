import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import { Section } from "react-scroll-section";
import crossmint from "../assets/crossmint.png";
import coinbase from "../assets/Coinbase-icon-symbol-1.png";
import { SectionHeader } from "./Titles";
function TextBlock(props: TypographyProps) {
  return <Typography align={"left"} paragraph={true} {...props} />;
}

function HeaderText(props: TypographyProps) {
  return (
    <TextBlock color={"#12284B"} variant={"h3"} align={"center"} {...props} />
  );
}
const Img = styled("img")({});
const cards = [
  {
    title: "How to manage a Crossmint account",
    description: "Crossmint allows users to purchase NFTs with a credit card.",
    link: "https://www.crossmint.io/faq/users#what_is_crossmint",
    img: (
      <Img
        width={"75px"}
        style={{ padding: "9px" }}
        src={crossmint}
        alt={"Crossmint logo"}
      />
    ),
  },
  {
    title: "How to set up a Metamask Wallet",
    description:
      "MetaMask is a browser extension for accessing Ethereum enabled distributed applications.",
    link: "https://metamask.zendesk.com/hc/en-us/articles/360015489531-Getting-started-with-MetaMask",
    img: (
      <Img
        width={"90px"}
        src={
          "https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png"
        }
        alt={"Metamask"}
        sx={{
          mb: {
            xs: 1,
          },
        }}
      />
    ),
  },
  {
    title: "How to set up a Coinbase Wallet",
    description:
      "Coinbase is a secure online platform for buying, selling, transferring, and storing cryptocurrency.",
    link: "https://www.coinbase.com/wallet/tutorials",
    img: (
      <Img
        width={"80px"}
        src={coinbase}
        alt={"Coinbase logo"}
        style={{ padding: "9px" }}
      />
    ),
  },
];
export function HowTo() {
  return (
    <Box
      component={Section}
      id={"how-to"}
      display={"flex"}
      alignItems={"center"}
      sx={{
        mb: 8,
        px: {
          xs: 1,
        },
      }}
    >
      {" "}
      <Container>
        <SectionHeader>How To</SectionHeader>
        <Grid container spacing={1} justifyContent={"space-around"}>
          {cards.map((card) => (
            <Grid key={card.title} item xs={12} sm={6} md={4}>
              <Card
                variant="outlined"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 1,
                  minHeight: {
                    xs: "300px",
                    sm: "330px",
                  },
                }}
              >
                {card.img}
                <HeaderText variant={"h6"} sx={{ mt: 1 }}>
                  {card.title}
                </HeaderText>

                <TextBlock align={"center"}>{card.description}</TextBlock>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  rel="noreferrer noopener"
                  target={"_blank"}
                  href={card.link}
                >
                  Learn How
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
