import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ReactNode } from "react";

type BasicPaperDisplayProps = {
  title: ReactNode;
  body: ReactNode;
};
export default function BasicPaperDisplay({
  title,
  body,
}: BasicPaperDisplayProps) {
  return (
    <Grid container justifyContent={"center"}>
      <Grid item lg={6}>
        <Paper
          elevation={0}
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant={"h3"} align={"center"} paragraph={true}>
            {title}
          </Typography>
          <Divider flexItem sx={{ mb: 2 }} />
          <Typography paragraph={true} align={"center"}>
            {body}
          </Typography>
          <Button
            component={Link}
            to={"/"}
            variant={"contained"}
            color={"primary"}
            size={"large"}
          >
            Return home
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}
