import BasicPaperDisplay from "../components/BasicPaperDisplay";
export default function NoTokens() {
  return (
    <BasicPaperDisplay
      title={"No Heritage Pass Found"}
      body={
        "Try connecting another wallet or verify that the email address you've entered is the one associated with your Heritage Pass purchase."
      }
    />
  );
}
