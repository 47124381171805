const aspectRatio = 0.20467;
export const VeraLogo = ({ color = "white", height = 35 }) => {
  const width = Math.floor(height * (1 / aspectRatio));
  return (
    <svg
      width={`${width}`}
      height={`${height}`}
      viewBox={`0 0 171 35`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7658 29.831C10.6693 29.8292 10.5762 29.7952 10.5013 29.7344C10.4263 29.6736 10.3739 29.5895 10.3522 29.4954L2.24335 5.9962L1.07802 5.91819C0.817929 5.89302 0.714722 5.78981 0.714722 5.5549V4.8535C0.714722 4.59342 0.817929 4.49023 1.07802 4.49023H3.07397C3.35922 4.49023 3.46242 4.59342 3.56561 4.87867C6.02805 12.0034 10.7155 25.6579 10.9756 26.5389L11.182 27.3158H11.3103L11.4924 26.5389C11.7525 25.6831 16.4927 12.0822 18.9527 4.87867C19.0559 4.64711 19.1347 4.49023 19.4443 4.49023H20.3244C20.556 4.49023 20.6348 4.6186 20.6348 4.7218C20.5573 5.06484 20.4535 5.40141 20.3244 5.72857L12.0587 29.4845C12.0321 29.5759 11.9783 29.6571 11.9044 29.717C11.8305 29.777 11.74 29.813 11.6451 29.8201H10.7658V29.831Z"
        fill={color}
      />
      <path
        d="M30.4494 19.2902H23.0361C22.776 19.2902 22.6728 19.187 22.6728 18.927V18.4101C22.6651 18.3607 22.6691 18.3101 22.6846 18.2625C22.7001 18.2149 22.7267 18.1716 22.7621 18.1362C22.7975 18.1007 22.8408 18.0742 22.8884 18.0587C22.936 18.0432 22.9866 18.0391 23.0361 18.0468H29.3117C29.258 16.4393 28.3049 14.4182 25.8148 14.4182C23.2483 14.4182 21.6693 16.4142 21.6693 19.651C21.6693 23.0975 23.5621 24.7554 26.2561 24.7554C27.5553 24.7489 28.8078 24.27 29.7799 23.408C29.9368 23.2796 30.0651 23.1479 30.1683 23.1479C30.2715 23.1479 30.3504 23.2016 30.4787 23.3551L30.7388 23.6404C30.8957 23.8224 30.8957 23.8719 30.8957 23.9508C30.8957 24.1077 30.6885 24.3393 30.4821 24.5213C29.2835 25.5057 27.786 26.0535 26.2351 26.0751C22.5318 26.0751 20.1223 23.8476 20.1223 19.6485C20.1223 15.7103 22.5822 13.1472 25.8752 13.1472C26.7885 13.0838 27.6998 13.2971 28.4901 13.7592C29.2805 14.2214 29.9132 14.9109 30.3059 15.7379C30.7155 16.7225 30.91 17.7831 30.8764 18.8489C30.8818 18.907 30.8746 18.9656 30.8553 19.0206C30.836 19.0757 30.805 19.1259 30.7644 19.1678C30.7238 19.2097 30.6747 19.2424 30.6203 19.2635C30.5659 19.2846 30.5076 19.2937 30.4494 19.2902Z"
        fill={color}
      />
      <path
        d="M38.1839 20.7381C38.1344 20.7458 38.0838 20.7417 38.0362 20.7262C37.9886 20.7107 37.9453 20.6841 37.9099 20.6487C37.8745 20.6133 37.848 20.5701 37.8325 20.5224C37.8169 20.4748 37.8129 20.4242 37.8206 20.3748V19.9083C37.8152 19.8592 37.8208 19.8096 37.8371 19.7631C37.8534 19.7165 37.88 19.6742 37.9149 19.6393C37.9498 19.6044 37.9921 19.5778 38.0387 19.5615C38.0852 19.5452 38.1349 19.5395 38.1839 19.545H40.2579C41.9434 19.545 43.316 18.6112 43.316 16.9039C43.3573 16.5808 43.3267 16.2526 43.2264 15.9426C43.1261 15.6327 42.9586 15.3488 42.7359 15.1112C42.5131 14.8735 42.2406 14.688 41.9379 14.5678C41.6351 14.4477 41.3095 14.396 40.9845 14.4163C40.2285 14.3615 39.4698 14.4743 38.7624 14.7467C38.0551 15.0191 37.4167 15.4442 36.8927 15.9919V25.3198C36.8927 25.5799 36.7895 25.683 36.5294 25.683H35.6988C35.6494 25.6908 35.5988 25.6867 35.5512 25.6712C35.5035 25.6557 35.4603 25.6291 35.4248 25.5937C35.3894 25.5583 35.3629 25.5151 35.3474 25.4675C35.3319 25.4198 35.3278 25.3692 35.3355 25.3198V13.8978C35.3355 13.6662 35.4639 13.5622 35.6988 13.5622H36.3977C36.6578 13.5622 36.761 13.6654 36.761 13.8978L36.839 14.6965C37.8231 13.5311 39.4055 13.1452 41.1908 13.1452C41.6771 13.1062 42.166 13.1719 42.6247 13.338C43.0834 13.504 43.5011 13.7665 43.8497 14.1077C44.1983 14.4489 44.4696 14.8609 44.6455 15.3159C44.8213 15.7709 44.8975 16.2583 44.869 16.7453C44.8659 17.5539 44.583 18.3365 44.0683 18.9601C43.5537 19.5838 42.8391 20.0101 42.0458 20.1667C42.8764 20.3731 43.3143 20.8379 43.7816 21.9026L44.4528 23.4815C44.7633 24.2366 45.124 24.3373 45.8254 24.4405C46.1107 24.4942 46.1887 24.5974 46.1887 24.829V25.3206C46.1887 25.5807 46.0855 25.6839 45.8254 25.6839H45.4621C44.0644 25.6839 43.5442 25.3206 42.9745 23.9732L42.326 22.4446C41.8595 21.3287 41.4459 20.7339 40.3049 20.7339H38.1839V20.7381Z"
        fill={color}
      />
      <path
        d="M86.9031 14.9861C90.8162 15.3494 92.7592 18.0442 92.7592 22.2148C92.7592 27.5793 89.5711 29.832 83.3282 29.832H75.6859C75.633 29.8397 75.5792 29.835 75.5285 29.8183C75.4778 29.8015 75.4318 29.7731 75.394 29.7354C75.3563 29.6976 75.3279 29.6516 75.3111 29.6009C75.2943 29.5502 75.2896 29.4963 75.2974 29.4435V5.96861L74.1815 5.91576C73.95 5.89059 73.8183 5.78739 73.8183 5.55247V4.85361C73.8103 4.80411 73.8143 4.75342 73.8297 4.70572C73.8452 4.65803 73.8717 4.61467 73.9071 4.57921C73.9426 4.54376 73.9859 4.51722 74.0336 4.50176C74.0813 4.48631 74.132 4.48239 74.1815 4.49032H82.9909C87.4468 4.49032 90.4009 5.86291 90.4009 10.0864C90.4034 13.043 88.6675 14.5976 86.9031 14.9861ZM82.9926 5.91827H76.93V28.4032H83.3064C87.5802 28.4032 91.0519 27.3662 91.0519 22.1871C91.0519 16.6414 87.659 15.8645 83.642 15.8645H79.265C79.2122 15.8723 79.1583 15.8675 79.1076 15.8508C79.0569 15.834 79.0109 15.8056 78.9731 15.7679C78.9354 15.7301 78.907 15.6841 78.8902 15.6334C78.8735 15.5827 78.8687 15.5288 78.8765 15.476V14.8275C78.8765 14.5423 79.0049 14.439 79.265 14.439H83.5916C87.2697 14.439 88.7204 13.0916 88.7204 10.062C88.7179 6.93009 86.5684 5.91827 82.9935 5.91827H82.9926Z"
        fill={color}
      />
      <path
        d="M121.686 24.9899C121.686 25.0931 121.632 25.1963 121.375 25.4564C121.012 25.7668 120.933 25.8197 120.805 25.8197C120.702 25.8197 120.52 25.7416 120.078 25.2743C119.705 24.8953 119.419 24.4393 119.239 23.9382C119.06 23.4371 118.993 22.903 119.041 22.3731V19.393C115.801 19.5751 112.643 19.8595 112.643 22.6575C112.611 22.9455 112.643 23.2372 112.738 23.5112C112.833 23.7851 112.987 24.0343 113.191 24.2405C113.395 24.4466 113.642 24.6045 113.915 24.7026C114.188 24.8006 114.479 24.8363 114.768 24.807C115.836 24.8271 116.875 24.4599 117.694 23.7734C117.9 23.5913 117.979 23.5418 118.082 23.5418C118.135 23.5418 118.239 23.645 118.471 23.9303C118.677 24.2155 118.702 24.2658 118.702 24.3439C118.702 24.4722 118.471 24.7071 118.235 24.8892C117.201 25.6565 115.948 26.0726 114.66 26.0764C112.693 26.0764 111.085 25.0394 111.085 22.7079C111.085 19.1078 114.892 18.434 119.037 18.2772V16.5916C119.037 14.9095 117.897 14.3389 116.293 14.3389C115.51 14.3108 114.733 14.489 114.04 14.8558C113.677 15.0873 113.445 15.3474 113.445 15.5546C113.445 15.8147 113.78 15.9431 113.78 16.4347C113.777 16.5593 113.748 16.682 113.696 16.7954C113.645 16.9088 113.571 17.0107 113.479 17.0951C113.387 17.1795 113.28 17.2446 113.162 17.2867C113.045 17.3288 112.92 17.3469 112.796 17.34C112.641 17.3321 112.489 17.2923 112.35 17.2229C112.211 17.1536 112.088 17.0563 111.988 16.9372C111.888 16.818 111.814 16.6795 111.77 16.5304C111.727 16.3813 111.714 16.2248 111.734 16.0706C111.77 15.5872 111.94 15.1234 112.224 14.7303C112.507 14.3372 112.894 14.0302 113.341 13.8431C114.253 13.3694 115.269 13.1292 116.296 13.1442C119.119 13.1442 120.599 14.4379 120.599 16.668V22.2137C120.539 22.9225 120.732 23.6295 121.144 24.2096C121.507 24.7021 121.686 24.8339 121.686 24.9899Z"
        fill={color}
      />
      <path
        d="M135.71 21.8791C135.66 22.3507 135.705 22.8275 135.843 23.2813C135.981 23.7351 136.208 24.1565 136.513 24.5203C136.773 24.8559 136.773 24.9087 136.773 25.0371C136.773 25.1151 136.747 25.2192 136.462 25.4792C136.074 25.7897 135.995 25.8148 135.867 25.8148C135.763 25.8148 135.606 25.7116 135.271 25.3727C134.896 24.9238 134.598 24.4154 134.391 23.8684C133.911 24.5614 133.267 25.125 132.516 25.5092C131.766 25.8934 130.932 26.0863 130.089 26.0707C126.539 26.0707 124.778 23.3507 124.778 19.7229C124.778 15.7847 126.592 13.1427 130.167 13.1427C131.671 13.1829 133.101 13.8052 134.156 14.8786V1.80381L133.197 1.75096C132.965 1.72579 132.834 1.62258 132.834 1.38767V0.767667C132.826 0.718161 132.83 0.667475 132.845 0.619779C132.861 0.572083 132.887 0.528722 132.922 0.49327C132.958 0.457818 133.001 0.431297 133.049 0.415844C133.097 0.400391 133.147 0.396448 133.197 0.404373H135.321C135.374 0.396611 135.428 0.401321 135.479 0.418097C135.529 0.434873 135.575 0.46326 135.613 0.501003C135.651 0.538745 135.679 0.584803 135.696 0.635474C135.713 0.686145 135.717 0.740027 135.71 0.792836L135.71 21.8791ZM134.157 22.4748V16.3611C133.678 15.7997 133.087 15.3441 132.423 15.0231C131.759 14.7022 131.035 14.523 130.297 14.4969C127.342 14.4969 126.334 16.8813 126.334 19.7045C126.334 22.6846 127.681 24.73 130.193 24.73C130.997 24.7541 131.792 24.5563 132.491 24.1584C133.19 23.7604 133.766 23.1776 134.156 22.474L134.157 22.4748Z"
        fill={color}
      />
      <path
        d="M142.12 21.932C142.062 22.7926 142.309 23.6461 142.819 24.3416C143.155 24.8081 143.208 24.887 143.208 25.0128C143.208 25.116 143.155 25.1949 142.872 25.455C142.509 25.7906 142.406 25.8182 142.276 25.8182C142.173 25.8182 142.016 25.7402 141.605 25.2729C140.839 24.3463 140.467 23.1562 140.568 21.9581V1.80808L139.609 1.75438C139.378 1.72921 139.246 1.62601 139.246 1.39109V0.771092C139.238 0.721586 139.242 0.670899 139.257 0.623204C139.273 0.575508 139.299 0.532147 139.335 0.496695C139.37 0.461243 139.414 0.434697 139.461 0.419243C139.509 0.40379 139.56 0.399873 139.609 0.407798H141.736C141.789 0.400035 141.843 0.40472 141.893 0.421496C141.944 0.438273 141.99 0.466685 142.028 0.504427C142.066 0.54217 142.094 0.588202 142.111 0.638873C142.128 0.689545 142.132 0.743452 142.125 0.796261L142.12 21.932Z"
        fill={color}
      />
      <path
        d="M169.975 13.563C170.206 13.563 170.285 13.6662 170.285 13.8231C170.237 14.2416 170.133 14.6516 169.975 15.0421L166.528 26.3626C165.623 29.3175 164.766 31.9335 163.68 33.1777C163.378 33.5819 162.988 33.9113 162.538 34.1405C162.089 34.3698 161.593 34.4928 161.089 34.4999C160.155 34.4999 159.3 34.0334 159.3 33.0736C159.295 32.944 159.315 32.8145 159.361 32.693C159.407 32.5715 159.476 32.4605 159.566 32.3665C159.655 32.2725 159.763 32.1975 159.882 32.1461C160.001 32.0946 160.129 32.0677 160.259 32.0669C160.427 32.0567 160.594 32.0984 160.738 32.1863C160.881 32.2743 160.994 32.4042 161.061 32.5585C161.19 32.7658 161.268 33.0007 161.553 33.0007C162.252 33.0007 163.082 32.1986 164.169 28.9584L165.153 25.9288C164.661 25.9288 164.454 25.8256 164.273 25.3583L160.644 15.0723C160.466 14.6852 160.344 14.2747 160.281 13.8533C160.281 13.6964 160.359 13.568 160.591 13.568H161.394C161.729 13.568 161.807 13.6964 161.939 14.0597L164.894 22.635C165.125 23.255 165.307 23.9035 165.489 24.474H165.618L168.676 14.0588C168.754 13.6955 168.858 13.5672 169.193 13.5672H169.976L169.975 13.563Z"
        fill={color}
      />
      <path
        d="M156.919 19.2902H149.506C149.246 19.2902 149.143 19.187 149.143 18.927V18.4101C149.135 18.3607 149.139 18.3101 149.155 18.2625C149.17 18.2149 149.197 18.1716 149.232 18.1362C149.267 18.1007 149.311 18.0742 149.358 18.0587C149.406 18.0432 149.457 18.0391 149.506 18.0468H155.782C155.729 16.4393 154.775 14.4182 152.285 14.4182C149.718 14.4182 148.139 16.4142 148.139 19.651C148.139 23.0975 150.032 24.7554 152.726 24.7554C154.025 24.7489 155.278 24.27 156.25 23.408C156.407 23.2796 156.535 23.1479 156.638 23.1479C156.741 23.1479 156.82 23.2016 156.949 23.3551L157.209 23.6404C157.366 23.8224 157.366 23.8719 157.366 23.9508C157.366 24.1077 157.159 24.3393 156.952 24.5213C155.753 25.5057 154.256 26.0535 152.705 26.0751C149.002 26.0751 146.592 23.8476 146.592 19.6485C146.592 15.7103 149.052 13.1472 152.345 13.1472C153.259 13.0838 154.17 13.2971 154.96 13.7592C155.75 14.2214 156.383 14.9109 156.776 15.7379C157.185 16.7225 157.38 17.7831 157.346 18.8489C157.352 18.907 157.345 18.9656 157.325 19.0206C157.306 19.0757 157.275 19.1259 157.234 19.1678C157.194 19.2097 157.145 19.2424 157.09 19.2635C157.036 19.2846 156.978 19.2937 156.919 19.2902Z"
        fill={color}
      />
      <path
        d="M99.7381 20.7381C99.6886 20.7458 99.638 20.7417 99.5904 20.7262C99.5428 20.7107 99.4995 20.6841 99.4641 20.6487C99.4287 20.6133 99.4021 20.5701 99.3866 20.5224C99.3711 20.4748 99.3671 20.4242 99.3748 20.3748V19.9083C99.3693 19.8592 99.375 19.8096 99.3913 19.7631C99.4076 19.7165 99.4342 19.6742 99.4691 19.6393C99.5039 19.6044 99.5463 19.5778 99.5928 19.5615C99.6394 19.5452 99.689 19.5395 99.7381 19.545H101.812C103.498 19.545 104.87 18.6112 104.87 16.9039C104.911 16.5808 104.881 16.2526 104.781 15.9426C104.68 15.6327 104.513 15.3488 104.29 15.1112C104.067 14.8735 103.795 14.688 103.492 14.5678C103.189 14.4477 102.864 14.396 102.539 14.4163C101.783 14.3615 101.024 14.4743 100.317 14.7467C99.6093 15.0191 98.9708 15.4442 98.4469 15.9919V25.3198C98.4469 25.5799 98.3437 25.683 98.0836 25.683H97.253C97.2035 25.6908 97.1529 25.6867 97.1053 25.6712C97.0577 25.6557 97.0144 25.6291 96.979 25.5937C96.9436 25.5583 96.9171 25.5151 96.9016 25.4675C96.886 25.4198 96.8819 25.3692 96.8897 25.3198V13.8978C96.8897 13.6662 97.0181 13.5622 97.253 13.5622H97.9519C98.212 13.5622 98.3151 13.6654 98.3151 13.8978L98.3932 14.6965C99.3773 13.5311 100.96 13.1452 102.745 13.1452C103.231 13.1062 103.72 13.1719 104.179 13.338C104.638 13.504 105.055 13.7665 105.404 14.1077C105.752 14.4489 106.024 14.8609 106.2 15.3159C106.375 15.7709 106.452 16.2583 106.423 16.7453C106.42 17.5539 106.137 18.3365 105.622 18.9601C105.108 19.5838 104.393 20.0101 103.6 20.1667C104.431 20.3731 104.869 20.8379 105.336 21.9026L106.007 23.4815C106.317 24.2366 106.678 24.3373 107.38 24.4405C107.665 24.4942 107.743 24.5974 107.743 24.829V25.3206C107.743 25.5807 107.64 25.6839 107.38 25.6839H107.016C105.619 25.6839 105.098 25.3206 104.529 23.9732L103.88 22.4446C103.414 21.3287 103 20.7339 101.859 20.7339H99.7347L99.7381 20.7381Z"
        fill={color}
      />
      <path
        d="M60.1395 24.9903C60.1395 25.0935 60.0866 25.1967 59.829 25.4567C59.4657 25.7672 59.3869 25.82 59.2585 25.82C59.1553 25.82 58.9733 25.742 58.5319 25.2747C58.1584 24.8957 57.8721 24.4397 57.693 23.9386C57.5139 23.4375 57.4463 22.9034 57.495 22.3735V19.3934C54.2548 19.5754 51.0968 19.8599 51.0968 22.6579C51.0641 22.9459 51.0964 23.2376 51.1912 23.5115C51.286 23.7854 51.441 24.0346 51.6448 24.2408C51.8485 24.447 52.0959 24.6049 52.3687 24.7029C52.6415 24.801 52.9328 24.8367 53.2212 24.8074C54.2894 24.8274 55.3289 24.4603 56.1475 23.7737C56.3539 23.5917 56.4328 23.5422 56.536 23.5422C56.5888 23.5422 56.6929 23.6454 56.9245 23.9306C57.1308 24.2159 57.156 24.2662 57.156 24.3442C57.156 24.4726 56.9245 24.7075 56.6895 24.8896C55.6552 25.6569 54.4024 26.0729 53.1146 26.0767C51.1472 26.0767 49.5397 25.0398 49.5397 22.7082C49.5397 19.1081 53.3462 18.4344 57.4916 18.2775V16.592C57.4916 14.9098 56.3514 14.3393 54.7473 14.3393C53.9641 14.3112 53.1872 14.4894 52.4946 14.8561C52.1313 15.0877 51.8989 15.3478 51.8989 15.555C51.8989 15.8151 52.2345 15.9434 52.2345 16.4351C52.231 16.5597 52.2026 16.6823 52.1509 16.7957C52.0992 16.9092 52.0252 17.0111 51.9335 17.0955C51.8418 17.1798 51.7341 17.245 51.6167 17.2871C51.4994 17.3291 51.3748 17.3473 51.2504 17.3404C51.0949 17.3328 50.9426 17.2932 50.8032 17.224C50.6638 17.1547 50.5402 17.0574 50.4402 16.9381C50.3402 16.8188 50.266 16.6801 50.2222 16.5307C50.1784 16.3814 50.1659 16.2245 50.1857 16.0701C50.2223 15.5868 50.3919 15.1229 50.6755 14.7298C50.9592 14.3368 51.346 14.0297 51.7932 13.8426C52.7052 13.3688 53.7205 13.1287 54.7481 13.1437C57.5713 13.1437 59.0504 14.4375 59.0504 16.6675V22.2132C58.991 22.922 59.1842 23.629 59.5958 24.2092C59.9574 24.7025 60.1395 24.8342 60.1395 24.9903Z"
        fill={color}
      />
    </svg>
  );
};
