import BasicPaperDisplay from "../components/BasicPaperDisplay";
export function RedemptionSuccessful() {
  return (
    <BasicPaperDisplay
      title={"Success"}
      body={
        "Your Jilly Bag(s) has been successfully redeemed. For order inquiries, please contact NFT@verabradley.com"
      }
    />
  );
}
