import { Link } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import BasicPaperDisplay from "../components/BasicPaperDisplay";
export function MintSuccess1982() {
  const [params] = useSearchParams();
  const trx = params.get("trx");
  const link = trx ? (
    <>
      Check out{" "}
      <Link
        target="_blank"
        rel={"noreferrer noopener"}
        href={`https://etherscan.io/tx/${trx}`}
      >
        etherscan
      </Link>{" "}
      to verify the transaction.
    </>
  ) : (
    <></>
  );
  return (
    <BasicPaperDisplay
      title={"Success"}
      body={<>Your purchase was successful. {link}</>}
    />
  );
}
