import { Box, Container } from "@mui/material";

import { Outlet } from "react-router-dom";
import React from "react";

export const DefaultLayout: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Box
      component={"main"}
      sx={{ bgcolor: "grey.100", height: "100vh", width: "100%", pt: 4 }}
    >
      <Container>{children}</Container>
    </Box>
  );
};

export function DefaultLayoutPage() {
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
}
