import { useConfig } from "./common/core/config";
import { useState } from "react";
import { Box, Button, Container, Divider, Link, Stack } from "@mui/material";
import { Crossmint } from "./common/Crossmint";

import bag from "./assets/jilly_bags.gif";
import { MintWithEthButton } from "./components/MintWithEthButton";

import { useHeritagePassMintPrice } from "./hooks/useMintPrice";
import { HeaderText, TextBlock } from "./components/Titles";
import { ethers } from "ethers";

export default function Mint() {
  const [count, setCount] = useState(1);
  const config = useConfig();
  const ethPrice = useHeritagePassMintPrice();
  return (
    <Container>
      <main
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          background: "#FFFFFF",
        }}
      >
        <Stack
          direction={{
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
          }}
          spacing={{ xs: 2, sm: 2, md: 2, lg: 4 }}
        >
          <Stack direction="column" pt={4}>
            <HeaderText variant={"h2"} align={"left"} mb={4}>
              Mint
            </HeaderText>
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
              }}
              spacing={{ xs: 2, sm: 2, md: 8, lg: 8 }}
              alignItems="center"
              justifyContent={"flex-start"}
              height="auto"
              overflow="hidden"
              mb={4}
            >
              <Box
                sx={{
                  height: "200px",
                }}
              >
                <img
                  src={bag}
                  alt={"Jillybag Cambridge"}
                  style={{ height: "inherit" }}
                />
              </Box>
              <Stack
                direction="row"
                spacing={{ xs: 2, sm: 4, md: 8, lg: 8 }}
                alignItems="top"
                height="auto"
                object-fit="cover"
              >
                <TextBlock>
                  <span style={{ fontWeight: "900", color: "black" }}>
                    Heritage Pass NFT
                  </span>
                  <br />
                  <br />
                  <br />
                  <span>
                    <Button
                      onClick={
                        count > 1 ? () => setCount(count - 1) : undefined
                      }
                      variant="text"
                      size={"small"}
                      sx={{ minWidth: "32px" }}
                      color={"inherit"}
                    >
                      -
                    </Button>
                    {"  "}
                    {count}
                    {"  "}
                    <Button
                      size={"small"}
                      onClick={
                        count < config.maxTokensPerTransaction
                          ? () => setCount(count + 1)
                          : undefined
                      }
                      sx={{ minWidth: "32px" }}
                      variant={"text"}
                      color={"inherit"}
                    >
                      +
                    </Button>
                  </span>
                </TextBlock>
                <TextBlock>
                  Price
                  <br />
                  <br />
                  <br />${config.heritagePassCostDollars}
                </TextBlock>
                <TextBlock>
                  Total
                  <br />
                  <br />
                  <br />${config.heritagePassCostDollars * count}
                </TextBlock>
              </Stack>
            </Stack>
            <Divider
              sx={{ width: "60%", textAlign: "center", margin: "auto", mb: 4 }}
            />
            <TextBlock paragraph={true}>
              Vera Bradley's Heritage Pass will introduce loyal Vera Bradley
              customers to the metaverse. The Heritage Pass will be a
              standardized token featuring dynamic imagery of the iconic Jilly
              Bag in four heritage prints.
            </TextBlock>
            <TextBlock paragraph={true}>
              Bridging the digital and physical worlds, each Heritage Pass will
              come with a limited-edition Jilly Bag in one of the four special
              heritage prints featured in the NFTs. Redemption will open to pass
              holders via a token-gated portion of{" "}
              <Link href="https://worldofverabradley.com">
                worldofverabradley.com
              </Link>{" "}
              on Wednesday, September 28.
            </TextBlock>
            <TextBlock paragraph={true}>
              Customers who purchase the Heritage Pass will also receive early
              access to purchase the 1982 Collection.
            </TextBlock>
          </Stack>
          <Stack
            sx={{ backgroundColor: "#f3f3f3", color: "#333", width: "100%" }}
          >
            <Container
              sx={{
                px: {
                  xs: 0,
                  md: 2,
                },
                height: "90%",
                width: "90%",
                margin: "auto",
              }}
            >
              <Box>
                <TextBlock
                  sx={{
                    marginTop: "40px",
                    fontSize: "26px",
                    textAlign: "left",
                    fontWeight: "200",
                  }}
                >
                  Order Summary
                </TextBlock>
                <TextBlock
                  sx={{
                    marginTop: "40px",
                    fontSize: "20px",
                    fontWeight: "900",
                    color: "black",
                  }}
                >
                  <span style={{ float: "left" }}>Subtotal</span>
                  <span style={{ float: "right" }}>
                    ${config.heritagePassCostDollars * count}.00 |{" "}
                    {ethers.utils.formatEther(ethPrice.mul(count))} Eth
                  </span>
                </TextBlock>
              </Box>
              <Divider
                sx={{
                  width: "60%",
                  textAlign: "center",
                  margin: "120px auto 40px auto",
                  borderColor: "black",
                }}
              />
              <Box textAlign="center">
                <Box mb={2}>
                  <MintWithEthButton quantity={count} />
                </Box>
                <TextBlock textAlign="center">OR</TextBlock>
                <Box mt={2} justifyContent="center" display="flex">
                  <Crossmint
                    configKey={"crossmint"}
                    ethPrice={ethPrice}
                    quantity={count}
                  />
                </Box>
              </Box>
              <TextBlock mt={4}>
                The email address you use to buy with credit card will become
                your login when you go to redeem your Jilly Bag.
              </TextBlock>
            </Container>
          </Stack>
        </Stack>
      </main>
    </Container>
  );
}
