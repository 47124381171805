import { Typography, TypographyProps } from "@mui/material";

export function SectionHeader(props: TypographyProps) {
  return (
    <Typography
      color={"#12284B"}
      paragraph={true}
      variant={"h2"}
      align={"center"}
      {...props}
      sx={{
        ...props.sx,
        fontFamily: "Avenir-Heavy",
      }}
    />
  );
}

export function TextBlock(props: TypographyProps) {
  return <Typography align={"left"} paragraph={true} {...props} />;
}

export function HeaderText(props: TypographyProps) {
  return (
    <TextBlock color={"#12284B"} variant={"h3"} align={"center"} {...props} />
  );
}
