import { createContext, useContext, useMemo } from "react";
import { useCallback, useEffect, useState } from "react";
import { MagicUserMetadata } from "magic-sdk";
import { magic } from "./magic";
import { apiCall } from "../core/api";
import { useAccount, useDisconnect } from "wagmi";
type WalletContents = {
  totalTokenCount: number;
  unclaimedTokenCount: number;
};
export type MagicContextValue = {
  userMetadata: MagicUserMetadata | null;
  isLoading: boolean;
  logout: () => Promise<void>;
  login: (email: string, next?: string | null) => Promise<void>;
  loginWithCredential: () => Promise<void>;
  walletFetching: boolean;
  reload: () => void;
  walletContents: WalletContents | null;
  walletAddress: string | null;
};
export const MagicContext = createContext<MagicContextValue>({} as any);

export const MagicProvider = ({ children }: { children: React.ReactNode }) => {
  const { isConnected, address: connectedAddress } = useAccount();
  const { disconnect } = useDisconnect();
  const [userMetadata, setUserMetadata] = useState<MagicUserMetadata | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(true);
  const [refetchToken, setRefetch] = useState(0);
  const [walletFetching, setAddressFetching] = useState(true);
  const [walletContents, setWalletContents] = useState<WalletContents | null>(
    null
  );
  const [cmWallet, setCmWallet] = useState<string | null>(null);
  useEffect(() => {
    setIsLoading(true);
    magic.user
      .getMetadata()
      .then((i) => {
        setUserMetadata(i);
        setIsLoading(false);
      })
      .catch((e) => {
        setUserMetadata(null);
        setIsLoading(false);
      });
  }, [setUserMetadata, refetchToken]);

  const logout = useCallback(() => {
    return magic.user.logout().then(() => setRefetch((i) => i + 1));
  }, []);

  const login = useCallback((email: string, next?: string | null) => {
    const redirectURI = new URL(
      `/callback${next ? `?next=${next}` : ``}`,
      window.location.origin
    ).href;
    console.log({ redirectURI });
    return magic.auth
      .loginWithMagicLink({
        email,
        redirectURI,
        // optional redirect back to your app after magic link is clicked
      })
      .then(() => setRefetch((i) => i + 1));
  }, []);

  const loginWithCredential = useCallback(() => {
    return magic.auth.loginWithCredential().then(() => {
      if (isConnected) {
        disconnect();
      }
      setRefetch((i) => i + 1);
    });
  }, [isConnected, disconnect]);
  const walletAddress = (cmWallet || connectedAddress) ?? null;
  const value = useMemo(
    () => ({
      userMetadata,
      isLoading,
      logout,
      login,
      loginWithCredential,
      walletFetching,
      walletContents,
      reload: () => setRefetch((i) => i + 1),
      walletAddress,
    }),
    [
      userMetadata,
      isLoading,
      logout,
      login,
      loginWithCredential,
      walletFetching,
      walletContents,
      setRefetch,
      walletAddress,
    ]
  );
  useEffect(() => {
    if (userMetadata) {
      setAddressFetching(true);
      magic.user
        .generateIdToken()
        .then((t) => {
          return apiCall(`/api/get-wallet/${t}`);
        })
        .then((res) => {
          if (!res.ok) {
            setAddressFetching(false);
            return;
          }
          return res
            .json()
            .then(({ address }) => {
              setCmWallet(address);
              return apiCall(`/api/get-holdings/${address}`);
            })
            .then((res) => {
              setAddressFetching(false);
              if (!res.ok) {
                return;
              }
              return res.json().then((body) => setWalletContents(body));
            });
        });
    } else if (connectedAddress) {
      apiCall(`/api/get-holdings/${connectedAddress}`).then((res) => {
        setAddressFetching(false);
        if (!res.ok) {
          return;
        }
        return res.json().then((body) => setWalletContents(body));
      });
    }
  }, [userMetadata, connectedAddress]);
  return (
    <MagicContext.Provider value={value}>{children}</MagicContext.Provider>
  );
};
export const useMagic = () => useContext(MagicContext);
