import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  TypographyProps,
} from "@mui/material";
import { Section } from "react-scroll-section";
import cloudsBig from "../assets/clouds_big.jpg";
import cloudsBigMobile from "../assets/clouds_big_mobile.png";
import jilly from "../assets/jillybagsgroup_V2.png";
import mary from "../assets/Mary_v2.png";
import Grid2 from "@mui/material/Unstable_Grid2";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import Discord from "../icons/discord";
import { styled } from "@mui/system";
import { discordLink, instagramLink, twitterLink } from "../links";

function TextBlock(props: TypographyProps) {
  return (
    <Typography
      align={"left"}
      paragraph={true}
      {...props}
      sx={{
        ...props.sx,
        fontSize: "20px",
      }}
    />
  );
}

function HeaderText(props: TypographyProps) {
  return (
    <Typography
      paragraph={true}
      color={"#12284B"}
      variant={"h3"}
      align={"center"}
      {...props}
    />
  );
}

const Image = styled("img")({});
export function Roadmap() {
  return (
    <Box
      component={Section}
      id={"roadmap"}
      display={"flex"}
      alignItems={"center"}
      mb={8}
      sx={{
        justifyContent: "flex-start",
      }}
    >
      <Container
        sx={{
          px: {
            xs: 0,
            md: 2,
          },
        }}
      >
        <Box
          sx={{
            py: 6,
            px: 4,
            backgroundImage: {
              xs: `url(${cloudsBigMobile})`,
              sm: `url(${cloudsBig})`,
            },
            backgroundSize: {
              xs: "cover",
              sm: "auto",
            },
            backgroundPosition: {
              xs: "unset",
              sm: "0 -1000px",
            },
            backgroundRepeat: "no-repeat",
          }}
        >
          <HeaderText
            variant={"h2"}
            paragraph={false}
            sx={{ textTransform: "uppercase", fontFamily: "Avenir-Heavy" }}
          >
            Roadmap
          </HeaderText>
          <TextBlock>
            As we travel into the World of Vera Bradley, our top priority is
            creating an exciting and rewarding experience for Vera Bradley
            customers and new NFT collectors. Rooted in Vera Bradley’s brand
            ethos and culture, the World of Vera Bradley is committed to merging
            the physical and digital worlds through innovative NFT collections,
            limited-edition product offerings and special giveaways accessible
            only in the World of Vera Bradley.
          </TextBlock>
          <TextBlock>
            This roadmap lays the framework for what our team has in store, with
            surprises and delights to be revealed throughout the journey.
          </TextBlock>
          <Grid
            container
            flexDirection={{
              xs: "column-reverse",
              sm: "row",
            }}
          >
            <Grid
              item
              xs={12}
              sm={8}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              justifyContent={"center"}
            >
              <HeaderText
                variant={"h3"}
                paragraph={false}
                sx={{ fontFamily: "Avenir-Heavy" }}
              >
                Charity
              </HeaderText>
              <TextBlock>
                Through VB Cares, 100% of Vera Bradley’s primary net proceeds
                from the Heritage Pass and 1982 Collections will be donated to
                The Vera Bradley Foundation for Breast Cancer. Inspired by the
                loss of our co-founders’ dear friend, Mary Sloan in 1993, this
                organization has already made $37.5 million in contributions (to
                date) to support critical advancements in breast cancer
                research. Your purchase of a Vera Bradley NFT directly impacts
                this life-saving work. To learn more about the Foundation,
                please visit{" "}
                <Link
                  href={"https://verabradley.org"}
                  target={"_blank"}
                  rel={"noreferrer noopener"}
                >
                  verabradley.org
                </Link>
                .
              </TextBlock>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Image
                src={mary}
                alt={"mary"}
                sx={{
                  width: {
                    xs: "75%",
                    sm: "100%",
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ pt: 2 }}
            flexDirection={{
              xs: "column",
              sm: "row",
            }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              justifyContent={"center"}
            >
              <Image
                src={jilly}
                width={"95%"}
                alt={"jilly bag group"}
                sx={{
                  mb: { xs: 1, sm: 0 },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              justifyContent={"center"}
            >
              <HeaderText
                variant={"h3"}
                paragraph={false}
                sx={{ fontFamily: "Avenir-Heavy" }}
              >
                Limited-Edition Physicals
              </HeaderText>
              <TextBlock>
                In addition to the Heritage Pass providing early access to the
                1982 Collection, each Heritage Pass will come with a
                limited-edition Jilly Bag created exclusively for this inaugural
                Vera Bradley NFT drop. These very special Jilly Bags are
                available in four unique archived Vera Bradley prints.
                Purchasers of the Heritage Pass token will be surprised with
                their Jilly Bag pattern when it arrives!
              </TextBlock>
            </Grid>
          </Grid>

          <HeaderText
            align={"left"}
            variant={"h3"}
            paragraph={false}
            sx={{ fontFamily: "Avenir-Heavy" }}
          >
            The Future
          </HeaderText>
          <TextBlock>
            Please follow us on Instagram, Twitter and Discord to stay connected
            to our future World of Vera Bradley events and happenings.
          </TextBlock>
          <Grid2 container>
            <Grid2
              xs={4}
              xsOffset={4}
              display={"flex"}
              justifyContent={"center"}
            >
              <Button href={instagramLink}>
                <InstagramIcon sx={{ mr: 1, color: "#000000" }} />
              </Button>
              <Button href={twitterLink}>
                <TwitterIcon sx={{ mr: 1, color: "#000000" }} />
              </Button>
              <Button href={discordLink}>
                <Discord />
              </Button>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </Box>
  );
}
