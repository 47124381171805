import { useEffect, useState } from "react";
import { apiCall } from "./api";

export const useQuery = <T>(path: string, init?: RequestInit) => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [i, bump] = useState(0);

  const refetch = () => {
    bump((v) => v + 1);
  };
  useEffect(() => {
    (async function () {
      try {
        const res = await apiCall(path, { method: "GET", ...init });
        if (!res.ok) {
          setError(new Error(await res.json()));
          return;
        }
        setData(await res.json());
      } catch (e) {
        setError(e as Error);
      } finally {
        setLoading(false);
      }
    })();
  }, [path, init, i]);

  return {
    data,
    refetch,
    error,
    loading,
  };
};
