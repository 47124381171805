import {
  Divider,
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  Box,
  CircularProgress,
} from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useForm } from "react-hook-form";
import { redeemBag } from "../common/core/api";
import { useEffect, useState } from "react";
import { buildConfig } from "../common/build-config";
import { SiweMessage } from "siwe";
import { useAccount, useSignMessage } from "wagmi";
import NoTokens from "./NoTokens";
import AlreadyRedeemed from "./AlreadyRedeemed";
import { Loading } from "../components/Loading";

type FormData = {
  firstName: string;
  lastName: string;
  address: string;
  addressLineTwo: string;
  city: string;
  state: string;
  zip: number;
  phone: string;
};

function createSiweMessage(address: string) {
  const domain = window.location.host;
  const origin = window.location.origin;
  const message = new SiweMessage({
    domain,
    address,
    statement: "Sign this message in order to redeem your Jilly Bag",
    uri: origin,
    version: "1",
    chainId: Number(buildConfig.chainId),
  });
  return message.prepareMessage();
}
export default function RedeemPage() {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const { address } = useAccount();
  const [err, setErr] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");
  const { signMessageAsync } = useSignMessage({
    message,
  });
  useEffect(() => {
    if (address) {
      setMessage(createSiweMessage(address));
    }
  }, [address]);
  // not logged in  => redirect to log-in
  // no tokens => show no token-page

  const {
    isAuthenticated,
    getDidToken,
    walletFetching,
    walletContents,
    magicUserMetadata,
    reload,
  } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  if (!isAuthenticated) {
    return <Navigate to={"/sign-in"} />;
  }

  if (walletFetching) {
    return <Loading />;
  }
  if (magicUserMetadata && !walletContents) {
    return <NoTokens />;
  }
  if (
    walletContents &&
    walletContents.totalTokenCount + walletContents.unclaimedTokenCount === 0
  ) {
    return <NoTokens />;
  }

  if (walletContents && walletContents.unclaimedTokenCount === 0) {
    return <AlreadyRedeemed />;
  }

  async function submit(data: any) {
    setSubmitting(true);
    try {
      if (message) {
        const signature = await signMessageAsync();
        Object.assign(data, {
          signature,
          message,
        });
      } else {
        Object.assign(data, {
          didToken: await getDidToken(),
        });
      }
      const res = await redeemBag(data);
      reload();
      setSubmitting(false);
      navigate("/redemption-successful");
    } catch (e: any) {
      setSubmitting(false);
      setErr(JSON.parse(e.message).errors);
    }
  }
  //
  return (
    <Grid container justifyContent={"center"}>
      <Grid item xs={12} lg={6}>
        <Paper
          elevation={0}
          sx={{
            padding: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <form onSubmit={handleSubmit((data) => submit(data))}>
            <Typography variant={"h3"} align={"center"} paragraph={true}>
              Redeem Jilly Bag
            </Typography>
            <Divider flexItem sx={{ mb: 2 }} />

            <Typography paragraph={true} sx={{ alignSelf: "flex-start" }}>
              Shipping Address
            </Typography>
            {err.length > 0 && (
              <Box py={1}>
                <Typography>
                  Please fix these form errors and resubmit:
                </Typography>
                {err.map((e, i) => (
                  <Typography
                    key={e}
                    color={"error"}
                    paragraph={i === err.length - 1}
                  >
                    {i + 1}. {e}
                  </Typography>
                ))}
              </Box>
            )}
            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} md={6}>
                <TextField
                  label={"First name"}
                  fullWidth={true}
                  helperText={errors.firstName?.message}
                  {...register("firstName", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={"Last name"}
                  fullWidth={true}
                  helperText={errors.lastName?.message}
                  {...register("lastName", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  label={"Address"}
                  fullWidth={true}
                  helperText={errors.address?.message}
                  {...register("address", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label={"Apt, suite, etc"}
                  fullWidth={true}
                  helperText={errors.addressLineTwo?.message}
                  {...register("addressLineTwo")}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label={"City"}
                  fullWidth={true}
                  helperText={errors.city?.message}
                  {...register("city", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <TextField
                  label={"State / Province"}
                  fullWidth={true}
                  helperText={errors.state?.message}
                  {...register("state", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label={"Zip / postal code"}
                  helperText={errors.zip?.message}
                  fullWidth={true}
                  {...register("zip", {
                    required: true,
                    pattern: /[0-9]*/,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={"Phone"}
                  helperText={errors.phone?.message}
                  fullWidth={true}
                  {...register("phone", { required: true })}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant={"body2"} align={"center"}>
                  We need your phone number to contact you in case we have any
                  questions regarding your order
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent={"flex-end"}>
              <Grid item xs={12} md={4}>
                <Button
                  type={"submit"}
                  variant={"contained"}
                  color={"primary"}
                  size={"large"}
                  disabled={submitting}
                  fullWidth={true}
                >
                  {submitting
                    ? "Redeeming..."
                    : (walletContents?.unclaimedTokenCount ?? 0) > 1
                    ? `Redeem ${walletContents?.unclaimedTokenCount} tokens`
                    : `Redeem`}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}

/**
 *    <Grid container spacing={2} sx={{ mb: 2 }}>
 *             <Grid item sm={12} md={6}>
 *               <Typography variant={"body2"} align={"center"}>
 *                 Your wallet holds more than 1 Heritage Pass. Select which tokens
 *                 to redeem.
 *               </Typography>
 *             </Grid>
 *             <Grid item sm={12} md={6}>
 *               <Select defaultValue={["all"]} fullWidth={true} multiple={true}>
 *                 <MenuItem value={"all"}>All</MenuItem>
 *                 <MenuItem value={"1"}>Token 1</MenuItem>
 *                 <MenuItem value={"2"}>Token 2</MenuItem>
 *               </Select>
 *             </Grid>
 *           </Grid>
 */
