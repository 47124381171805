import { useConfig } from "../common/core/config";
import { useContractRead } from "wagmi";
import { ethers } from "ethers";

export const useMintPrice = (contractAddress: string, abi: any) => {
  const config = useConfig();
  const { data } = useContractRead({
    addressOrName: contractAddress,
    contractInterface: abi,
    functionName: "getPrice",
    chainId: config.chainId,
  });
  const ethPriceNumber = data ? data._hex / Math.pow(10, 18) : 0;
  return ethers.utils.parseEther(ethPriceNumber.toString());
};

export const useHeritagePassMintPrice = () => {
  const config = useConfig();
  return useMintPrice(config.contractAddress, config.contractABI);
};

export const use19282MintPrice = () => {
  const config = useConfig();
  return useMintPrice(config.address1982Collection, config.abi1982Collection);
};
