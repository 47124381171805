import {
  Box,
  Container,
  Grid,
  ImageList,
  ImageListItem,
  Stack,
  Typography,
  TypographyProps,
  useMediaQuery,
  Theme,
} from "@mui/material";

import bagOne from "./assets/jillybag_Cambridge-V1.jpg";

import bagTwo from "./assets/jillybag_MochaRouge-V1.jpg";
import bagThree from "./assets/jillybag_RosieRIoPink-V1.jpg";
import bagFour from "./assets/jillybag_RosieRioArmyGreen-V1.jpg";

import clouds from "./assets/clouds.png";

import { Section, useScrollSections } from "react-scroll-section";

import Faq from "./components/Faq";
import { VeraLogo } from "./components/VeraLogo";
import { HeresToBanner } from "./components/HeresToBanner";

import { Roadmap } from "./components/Roadmap";
import { HowTo } from "./components/HowTo";
import { SectionHeader } from "./components/Titles";
import { useEffect } from "react";
import { BuySection } from "./components/BuySection";

function TextBlock(props: TypographyProps) {
  return <Typography align={"left"} paragraph={true} {...props} />;
}

function HeaderText(props: TypographyProps) {
  return (
    <TextBlock color={"#12284B"} variant={"h3"} align={"center"} {...props} />
  );
}

export default function Home() {
  const sections = useScrollSections();
  useEffect(() => {
    const hash = window.location.hash;
    if (!hash) {
      return;
    }
    const match = sections.find(({ id }) => hash.substring(1) === id);
    if (!match) {
      return;
    }
    match.onClick();
  }, []);
  return (
    <main
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#FFFFFF",
      }}
    >
      <Box
        component={"section"}
        display={"flex"}
        alignItems={"center"}
        width={"100%"}
        mb={8}
      >
        <Container
          sx={{
            px: {
              xs: 0,
              md: 2,
            },
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${clouds})`,
              backgroundPositionY: "75%",
              py: 5,
              backgroundSize: "cover",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <HeaderText
              paragraph={false}
              style={{
                color: "#12284B",
                lineHeight: "52px",
                fontWeight: "bold",
                fontFamily: "Avenir-Heavy",
              }}
              variant={"h1"}
            >
              EXPLORE
            </HeaderText>
            <HeaderText
              paragraph={false}
              variant={"h3"}
              style={{
                color: "#12284B",
                lineHeight: "26px",
                fontFamily: "ArgentCF-RegularItalic",
              }}
            >
              the world of
            </HeaderText>
            <VeraLogo color={"#12284B"} height={70} />
          </Box>
        </Container>
      </Box>
      <BuySection />
      <Box component={"section"} display={"flex"} alignItems={"center"} mb={8}>
        <Container>
          <HeaderText variant={"h3"}>
            The World of Vera Bradley is a new metaverse concept space by Vera
            Bradley.
          </HeaderText>
          <br />
          <TextBlock textAlign="center">
            The World of Vera Bradley bridges Vera Bradley’s physical and
            digital worlds, intersecting past, present and future.
          </TextBlock>
          <TextBlock textAlign="center">
            In 1982, amidst a sea of basic black luggage, Vera Bradley
            co-founders Barbara Bradley Baekgaard and Patricia Miller pioneered
            a world of colorful travel.
          </TextBlock>
          <TextBlock textAlign="center">
            Over the last 40 years, a community has arisen — woven together by
            the strong spirit and commitment of women supporting women.
          </TextBlock>
          <TextBlock textAlign="center">
            Together, we are Vera Bradley, and we look forward to bringing our
            colorful world to life in Web3, where we can pioneer new
            opportunities for women today, tomorrow and for years to come.
          </TextBlock>
        </Container>
      </Box>
      <HeresToBanner />
      <Box
        component={Section}
        id="collection"
        display={"flex"}
        alignItems={"center"}
        mb={8}
        sx={{
          color: "black",
          mx: 2,
          justifyContent: "flex-start",
        }}
      >
        <Container>
          <SectionHeader>NFT Collections</SectionHeader>
          <TextBlock align={"center"} paragraph={true}>
            Vera Bradley's NFT collections will include a genesis drop comprised
            of 440 standardized tokens as well as a second drop of 1,982 unique
            generative art backgrounds created from 40 Vera Bradley heritage
            prints on the Ethereum blockchain. Both the Heritage Pass and 1982
            Collections raise the standards for fashion NFT collectables by
            donating 100% of Vera Bradley's primary net proceeds to the Vera
            Bradley Foundation for Breast Cancer.
          </TextBlock>
          <Stack direction="column">
            <Stack
              direction={{
                xs: "column",
                sm: "column",
                md: "column",
                lg: "column",
              }}
              spacing={{ xs: 2, sm: 2, md: 2, lg: 4 }}
              alignItems="center"
              height="auto"
              object-fit="cover"
              overflow="auto"
              mb={8}
              style={{ justifyContent: "center" }}
            >
              <Stack direction="column">
                <HeaderText variant={"h3"} style={{ color: "#12284B" }}>
                  Heritage Pass
                </HeaderText>
                <TextBlock paragraph={false}>
                  Vera Bradley’s Heritage Pass will introduce loyal Vera Bradley
                  customers to the metaverse. The Heritage Pass will be a
                  standardized token featuring dynamic imagery of the iconic
                  Jilly Bag in four heritage prints. Bridging the digital and
                  physical worlds, each Heritage Pass will come with a
                  limited-edition Jilly Bag in one of the four special heritage
                  prints featured in the NFTs. Customers who purchase the
                  Heritage Pass will also receive early access to purchase the
                  1982 Collection. The Heritage Pass will be available for
                  public sale on September 14 in both USD and ETH.
                </TextBlock>
              </Stack>
              <BagImageList />
            </Stack>

            <Stack
              spacing={{ xs: 0, sm: 0, md: 2, lg: 4 }}
              alignItems="center"
              width="100%"
              height="auto"
              object-fit="cover"
              overflow="auto"
              style={{ justifyContent: "center" }}
            >
              <Grid
                container
                spacing={3}
                direction={{
                  xs: "column-reverse",
                  sm: "column-reverse",
                  md: "column-reverse",
                  lg: "row",
                }}
              >
                <Grid item md={6} xs={12} display="flex">
                  <ImageList cols={2}>
                    <ImageListItem>
                      <img
                        loading="lazy"
                        src={require("./assets/877.png")}
                        alt="1982 Collection generative art"
                      />
                    </ImageListItem>
                    <ImageListItem>
                      <img
                        loading="lazy"
                        src={require("./assets/989.png")}
                        alt="1982 Collection generative art"
                      />
                    </ImageListItem>
                    <ImageListItem>
                      <img
                        loading="lazy"
                        src={require("./assets/1131.png")}
                        alt="1982 Collection generative art"
                      />
                    </ImageListItem>
                    <ImageListItem>
                      <img
                        loading="lazy"
                        src={require("./assets/1178.png")}
                        alt="1982 Collection generative art"
                      />
                    </ImageListItem>
                  </ImageList>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Stack direction="column">
                    <HeaderText variant={"h3"} style={{ color: "#12284B" }}>
                      1982 Collection
                    </HeaderText>
                    <TextBlock>
                      The 1982 Collection is composed of hand-drawn layers of
                      art that share the same hallmarks as Vera Bradley’s
                      prints: optimism, happiness, charm, energy and joy.
                    </TextBlock>
                    <TextBlock>
                      We hope that everyone in the World of Vera Bradley who
                      acquires this very special 1982 NFT will change out their
                      profile picture background in October to show their
                      support during National Breast Cancer Awareness Month.
                    </TextBlock>
                    <TextBlock>
                      Using original Vera Bradley prints (including rare
                      signature pink prints in honor of National Breast Cancer
                      Awareness Month) as inspiration, the team uses hand-drawn
                      derivatives and generative code to create unique assets
                      with a variety of rarity. The combination of art, history
                      and technology makes this one of the most unique fashion
                      NFT projects to date.
                    </TextBlock>
                    <TextBlock>
                      The 1982 Collection will be available for presale on
                      October 1 and public sale on October 3 and will be
                      available for purchase in both USD and ETH.
                    </TextBlock>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <HowTo />
      <Box
        component={Section}
        id={"vb-cares"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"stretch"}
        width={"100%"}
        mb={8}
      >
        <Container
          sx={{
            px: {
              xs: 0,
              md: 2,
            },
          }}
        >
          <Box
            sx={{
              backgroundImage: `url(${clouds})`,
              backgroundPositionY: "75%",
              minHeight: 316,
              backgroundSize: "cover",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <HeaderText
              variant={"h1"}
              paragraph={false}
              style={{
                color: "#12284B",
                textTransform: "uppercase",
                fontFamily: "Avenir-Heavy",
              }}
            >
              VB Cares
            </HeaderText>
            <HeaderText
              variant={"h3"}
              sx={{
                fontFamily: "ArgentCF-RegularItalic",
                fontStyle: "italic",
                textTransform: "lowercase",
              }}
            >
              Making Tomorrow More Beautiful Than Today
            </HeaderText>
            <Box
              width={{
                xs: "90%",
                sm: "60%",
              }}
              sx={{ textTransform: "uppercase" }}
            >
              <TextBlock textAlign="center">
                100% OF VERA BRADLEY'S PRIMARY NET PROCEEDS OF THE HERITAGE PASS
                AND 1982 COLLECTIONS WILL BE DONATED TO THE{" "}
                <span style={{ fontFamily: "Avenir-Heavy" }}>
                  VERA BRADLEY FOUNDATION FOR{" "}
                  <span style={{ whiteSpace: "nowrap" }}>BREAST CANCER.</span>
                </span>
              </TextBlock>
            </Box>
          </Box>
        </Container>
      </Box>
      <Roadmap />
      <Box
        component={Section}
        id={"faq"}
        display={"flex"}
        alignItems={"center"}
        sx={{
          minHeight: "calc(60vh)",
          pb: 8,
        }}
      >
        <Container>
          <SectionHeader>Frequently Asked Questions</SectionHeader>
          <Faq />
        </Container>
      </Box>
    </main>
  );
}

export const BagImageList = () => {
  const mobile = useMediaQuery<Theme>((t) => t.breakpoints.down("md"));
  return (
    <ImageList cols={mobile ? 2 : 4} gap={16}>
      <ImageListItem>
        <img src={bagOne} alt={"Jillybag Cambridge"} />
      </ImageListItem>
      <ImageListItem>
        <img src={bagTwo} alt={"jillybag MochaRouge"} />
      </ImageListItem>
      <ImageListItem>
        <img src={bagThree} alt={"jillybag rosierio pink"} />
      </ImageListItem>
      <ImageListItem>
        <img src={bagFour} alt={"jillybag rosierio army green"} />
      </ImageListItem>
    </ImageList>
  );
};
