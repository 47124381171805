import { useCallback } from "react";
import { useAccount } from "wagmi";
import { magic } from "../common/auth/magic";
import { useMagic } from "../common/auth/MagicContext";

export const useAuth = () => {
  const { isConnected } = useAccount();
  const {
    userMetadata,
    isLoading,
    logout,
    login,
    loginWithCredential,
    ...rest
  } = useMagic();

  const getDidToken = useCallback(async () => {
    if (!userMetadata || !userMetadata.email) {
      return undefined;
    }
    const token = await magic.user.generateIdToken();
    return token;
  }, [userMetadata]);
  return {
    isLoading,
    isAuthenticated: isConnected || !!userMetadata,
    magicUserMetadata: userMetadata,
    logout,
    login,
    loginWithCredential,
    getDidToken,
    ...rest,
  };
};
