import {
  AppBar,
  Grid,
  Button,
  Toolbar,
  Container,
  useMediaQuery,
  Theme,
  Menu,
  MenuItem,
  IconButton,
  Box,
} from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
// import opensea from '../icons/opensea.svg';
import TwitterIcon from "@mui/icons-material/Twitter";
import Logo from "../icons/vera-bradley-logo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import PinterestIcon from "@mui/icons-material/Pinterest";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useScrollSection } from "react-scroll-section";
import Discord from "../icons/discord";
import { Profile } from "./Profile";
import React, { useState } from "react";
import {
  discordLink,
  facebookLink,
  instagramLink,
  pinterestLink,
  twitterLink,
} from "../links";
import { SocialIconLinks } from "./Socials";
import { Link } from "react-router-dom";

export default function Header(props: { handleOpen: any }) {
  const mobile = useMediaQuery<Theme>((t) => t.breakpoints.down("sm"));
  return (
    <AppBar position="static" elevation={0} sx={{ backgroundColor: "white" }}>
      <Toolbar disableGutters={true} sx={{ flexWrap: "wrap" }}>
        <Box
          sx={{ backgroundColor: "#333333", color: "#fff", textAlign: "right" }}
          overflow={"hidden"}
          display={"flex"}
          height={"40px"}
          width={"100%"}
        >
          <Container>
            <Profile handleOpen={props.handleOpen} />
          </Container>
        </Box>

        {!mobile && <DesktopNav />}
        {mobile && <MobileNav />}
      </Toolbar>
    </AppBar>
  );
}

const DesktopNav = () => {
  const collectionSection = useScrollSection("collection");
  const vbCaresSection = useScrollSection("vb-cares");
  const roadmapSection = useScrollSection("roadmap");
  const howToSection = useScrollSection("how-to");
  const faqSection = useScrollSection("faq");
  return (
    <>
      <Container>
        <Grid container sx={{ display: "flex", flex: "0 0 100%", py: 1 }}>
          <Grid item xs={2} sx={{ display: { xs: "none", md: "block" } }}>
            <Link to={"/"}>
              <img
                height="40"
                src={Logo}
                alt="Logo"
                style={{ padding: "4px 0" }}
              />
            </Link>
          </Grid>
          <Grid
            item
            xs={10}
            sm={8}
            display={"flex"}
            alignItems={"center"}
            justifyContent={{ xs: "flex-start", sm: "center" }}
          />
          <Grid
            item
            xs={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            sx={{ color: "#000000" }}
          >
            <IconButton color={"inherit"} href={twitterLink} sx={{ mr: 1 }}>
              <TwitterIcon />
            </IconButton>
            <IconButton color={"inherit"} href={facebookLink} sx={{ mr: 1 }}>
              <FacebookIcon />
            </IconButton>
            <IconButton color={"inherit"} href={pinterestLink} sx={{ mr: 1 }}>
              <PinterestIcon />
            </IconButton>
            <IconButton color={"inherit"} href={instagramLink} sx={{ mr: 1 }}>
              <InstagramIcon />
            </IconButton>
            <IconButton color={"inherit"} href={discordLink}>
              <Discord />
            </IconButton>
          </Grid>
        </Grid>
      </Container>
      <Grid container sx={{ display: "flex", flex: "0 0 100%" }}>
        <Grid
          item
          sx={{ backgroundColor: "#f3f3f3", color: "#333" }}
          xs={12}
          display={"flex"}
          justifyContent={"center"}
          py={1}
        >
          <Button
            variant={"text"}
            size={"large"}
            color={"inherit"}
            href={"/#collection"}
            onClick={collectionSection.onClick}
          >
            NFT Collections
          </Button>
          <Button
            variant={"text"}
            size={"large"}
            color={"inherit"}
            href={"/#how-to"}
            onClick={howToSection.onClick}
          >
            How To
          </Button>
          <Button
            variant={"text"}
            size={"large"}
            color={"inherit"}
            href={"/#vb-cares"}
            onClick={vbCaresSection.onClick}
          >
            VB Cares
          </Button>
          <Button
            variant={"text"}
            size={"large"}
            color={"inherit"}
            onClick={roadmapSection.onClick}
            href={"/#roadmap"}
          >
            Roadmap
          </Button>

          <Button
            variant={"text"}
            size={"large"}
            color={"inherit"}
            onClick={faqSection.onClick}
            href={"/#faq"}
          >
            Frequently Asked Questions
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
const useMenuAnchor = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return {
    open,
    anchorEl,
    handleClick,
    handleClose,
  };
};
const MobileNav = () => {
  const { open, anchorEl, handleClick, handleClose } = useMenuAnchor();
  const collectionSection = useScrollSection("collection");
  const vbCaresSection = useScrollSection("vb-cares");
  const roadmapSection = useScrollSection("roadmap");
  const howToSection = useScrollSection("how-to");
  const faqSection = useScrollSection("faq");
  return (
    <>
      <Container sx={{ backgroundColor: "white" }}>
        <Grid container width={"100%"}>
          <Grid item xs={2} display={"flex"} alignItems={"center"}>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              open={open}
              onClose={handleClose}
              anchorEl={anchorEl}
              anchorOrigin={{ horizontal: "left", vertical: "top" }}
              anchorPosition={{ top: 0, left: 0 }}
              PaperProps={{
                sx: { minHeight: "400px", width: "70vw" },
              }}
            >
              <MenuItem
                sx={{
                  "&:hover": {
                    background: "white",
                  },
                }}
              >
                <Grid container>
                  <Grid item xs={8}>
                    <img height="30" src={Logo} alt="Logo" />
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      variant="text"
                      color="inherit"
                      onClick={handleClose}
                    >
                      Close
                      <CloseIcon />
                    </Button>
                  </Grid>
                </Grid>
              </MenuItem>
              <MenuItem divider onClick={collectionSection.onClick}>
                NFT Collections
              </MenuItem>
              <MenuItem divider onClick={howToSection.onClick}>
                How To
              </MenuItem>
              <MenuItem divider onClick={vbCaresSection.onClick}>
                VB Cares
              </MenuItem>
              <MenuItem divider onClick={roadmapSection.onClick}>
                Roadmap
              </MenuItem>

              <MenuItem divider onClick={faqSection.onClick}>
                Frequently Asked Questions
              </MenuItem>
            </Menu>
          </Grid>
          <Grid
            item
            xs={8}
            py={1}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img height="40" src={Logo} alt="Logo" />
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Container>
      <Container disableGutters={true} sx={{ bgcolor: "grey.100" }}>
        <Box
          display={"flex"}
          py={1}
          justifyItems={"center"}
          alignItems={"center"}
          justifyContent={"space-around"}
          width={"100%"}
        >
          <SocialIconLinks />
        </Box>
      </Container>
    </>
  );
};
