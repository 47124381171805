// NFT and Cryptocurrency (General)
// Vera Bradley’s NFT Collections
// How to Purchase

import { Link } from "@mui/material";

export const questionContent = [
  {
    title: "NFTs and Cryptocurrency",
    questions: [
      {
        title: "What does NFT stand for?",
        answer:
          "NFT stands for non-fungible token. Each token represents rights to a unique digital asset or collectible, and NFTs can be anything digital including digital art, drawings and music. The ownership of an NFT is recorded in the blockchain, and can be sold and traded. Most NFTs are part of the Ethereum blockchain, purchased using a digital wallet. Think of this blockchain as a digital ledger of transactions that exists online, which ensures that each NFT has only one owner at any given time and keeps a record of the history of a token’s ownership. Each NFT collection is associated with a smart contract, a unique computer code used to create or “mint” the NFT and which controls the function and transfer of the NFT, and information regarding the terms of sale and resale of the NFT between the buyers and sellers, including any resale royalty. To purchase most NFTs, you need cryptocurrency or a digital currency and/or digital wallet, and your cryptocurrency is managed by a special technology, referred to as a blockchain. However, as the metaverse evolves, many mainstream brands, such as Vera Bradley, are giving customers the opportunity to purchase NFTs in USD.",
      },

      {
        title: "What can I do with an NFT?",
        answer:
          "Once you have your NFT, you are 100% the owner of the token. Therefore, you can keep it, give it or trade it with others, just like a physical collectible. However, often there is only a limited license to the digital asset associated with the NFT and the creator(s) retain the intellectual property (including copyrights and trademarks) associated with the digital asset. The license specifies what you can and cannot do with the digital asset and the associated intellectual property. Often, the license provides the right to display the digital asset for personal, non-commercial use or to transfer the NFT to a third party on a secondary market. But the license terms can vary. It is important to read the license to understand what you can and cannot legally do with the digital asset and associated intellectual property.",
      },

      {
        title: "How do I purchase an NFT?",
        answer:
          "An NFT can typically be purchased with cryptocurrency and a cryptocurrency wallet. A cryptocurrency wallet can also be referred to as a blockchain wallet or digital wallet. Once you buy an NFT, your ownership of the NFT is recorded by the blockchain by associating the NFT with your wallet ID. An example of a digital wallet is the Metamask wallet, described below.",
      },
      {
        title: "Why do I have to manage my own wallet?",
        answer:
          "We want to be sure that you can be the real owner of your NFTs, in the same way that you are the owner of physical Vera Bradley items. This is why we want you to have your own wallet and feel comfortable controlling your own NFTs.",
      },
      {
        title: "Can you tell me more about cryptocurrency?",
        answer:
          "Cryptocurrency is a digital currency or, more technically, a store of value that uses blockchain security to record and secure online transactions. Cryptocurrency can be used to buy goods and services. Most commonly, cryptocurrency is used to buy unique digital items such as digital collectibles like avatars, wearables, skins or art, but more and more business are beginning to accept cryptocurrency as a form of payment. Cryptocurrency can be purchased with fiat currency (e.g., dollars) on various platforms. You should exercise caution and research in determining which kind of cryptocurrency to purchase because not all projects comply with various regulations. Ethereum (ETH) is a cryptocurrency that is accepted by the Vera Bradley metaverse website.",
      },
      {
        title: "How do I purchase ETH (Ethereum)?",
        answer:
          "You can purchase ETH through an online exchange such as Kraken, Coinbase or directly through Metamask. Once you have completed your purchase, you will be able to access your funds through Metamask or another compatible digital wallet to purchase an NFT. Please keep in mind that there are limits on how much you can buy and sell based on your account and the platform you are using.",
      },
      {
        title: "Which are the most used blockchains and what is gas?",
        answer: (
          <>
            Ethereum is the most frequently used blockchain. It requires “gas”
            to use — you can think of this as being similar to a processing fee
            for each transaction. You must have enough ETH in your wallet for
            the cost of your purchase and the gas. The gas fee enables your
            transaction to be processed on this network and to be stored on the
            blockchain. The gas fee per transaction can fluctuate based on
            network supply and demand. You can set a gas limit as the maximum
            amount of gas you are willing to use on any given transaction. If
            the actual amount of gas needed turns out to be lower than the limit
            you specified, the remaining gas will be returned to you. But if
            your limit is too low, either your transaction will not be processed
            or the transaction will fail and you may lose that gas. When making
            your purchase, your wallet can facilitate the calculation of the
            estimated gas price to make the transaction. See{" "}
            <a href="https://metamask.zendesk.com/hc/en-us/articles/4404600179227-User-Guide-Gas">
              here
            </a>{" "}
            for more information on how Metamask estimates gas fees.
          </>
        ),
      },
      {
        title: "What is an NFT marketplace?",
        answer:
          "Following the end of the primary sales, your NFTs are automatically visible and tradeable on OpenSea — the leading open NFT marketplace — and potentially on other platforms. NFT marketplaces enable creators and collectors to buy, sell and create. After connecting your wallet, you can purchase, auction or sell a wide range of NFTs.",
      },
      {
        title: "What is a digital wallet and how do I get one?",
        answer: (
          <>
            A digital wallet is typically a piece of software that is used to
            transact with cryptocurrency and tokens. You don't actually store
            cryptocurrency or NFTs in your wallet. Each wallet has a unique
            wallet ID (referred to as a public key). Each NFT has a unique token
            ID. The blockchain records ownership of an NFT by associating its
            unique token ID with a unique wallet ID and storing that record on
            the blockchain. To buy or sell an NFT, the wallet owner must enter a
            private key (which is like a password) to authorize the transaction
            via the wallet. The public and private keys are cryptographically
            generated. With most digital wallets, you hold the keys and are in
            charge of your own assets. Your assets are only as safe as your
            private key, so it is advisable to back it up and not share it with
            anyone. Never give your Secret Recovery Phrase or your private
            key(s) to anyone or any site, unless you want them to have full
            control over your assets. To make the process of setting up a wallet
            user-friendly, there are many software wallets, such as Metamask,
            which equip you with a wallet address, key vault, secure login and
            backup options to manage your digital assets securely. Metamask runs
            as a browser extension and/or mobile app, and you can be up and
            running in just a couple minutes. Learn more{" "}
            <Link
              rel={"noreferrer noopener"}
              target={"_blank"}
              href={"https://metamask.io/faqs/"}
            >
              here.
            </Link>
          </>
        ),
      },

      {
        title: "How do I set up a Metamask wallet?",
        answer:
          "Open Google Chrome, Firefox or Brave on your computer and install the Metamask browser extension. Alternatively, download and install the Metamask App for iPhone or Android. Open the app and choose “Create Wallet”. Create a username and password. Important: Make sure you safely record your Secret Recovery Phrase and store it somewhere secret and safe, as this is the only way to recover your wallet if you lose your device, or ever need to install it on another computer.",
      },
    ],
  },
  {
    title: "Vera Bradley’s NFT Collections",
    questions: [
      {
        title: "What is the World of Vera Bradley?",
        answer:
          "The World of Vera Bradley is a new metaverse concept space by Vera Bradley, bridging Vera Bradley’s physical and digital world, intersecting past, present and future. The World of Vera Bradley will bring Vera Bradley’s colorful world to life in Web3 with a mission to pioneer new NFT and cryptocurrency opportunities for women and provide a pathway and digital hub for all Vera Bradley metaverse experiences, journeys and special products. The World of Vera Bradley will come alive its first NFT collections, slated to drop fall 2022.",
      },

      {
        title: "What are Vera Bradley’s first NFT collections?",
        answer:
          "Vera Bradley will journey into the metaverse with two NFT collections, including a genesis drop comprised of 440 standardized tokens as well as a second drop of 1,982 unique generative art backgrounds created from 40 unique Vera Bradley heritage prints on the Ethereum blockchain. Both the Heritage Pass and 1982 Collections raise the standards for fashion NFT collectables by donating 100% of Vera Bradley's primary net proceeds to the Vera Bradley Foundation for Breast Cancer.",
      },
      {
        title: "What is the Heritage Pass?",
        answer:
          "Available for purchase for approximately $82.00 USD* on September 14, 2022, Vera Bradley’s Heritage Pass will introduce Vera Bradley customers to the metaverse. The Heritage Pass will be a standardized token featuring dynamic imagery of the iconic Jilly Bag in four heritage prints. Bridging the digital and physical worlds, each Heritage Pass will come with a limited-edition Jilly Bag in one of the four special heritage prints featured in the NFTs. Customers who purchase the Heritage Pass will also receive early access to purchase the 1982 Collection. 100% of Vera Bradley’s primary net proceeds from the Heritage Pass collection will be donated to the Vera Bradley Foundation for Breast Cancer. *Please Note: The exact USD retail price will depend on ETH gas prices at time of purchase.",
      },
      {
        title: "What is the 1982 Collection?",
        answer:
          "Available for purchase for approximately $19.82 USD* with presale on October 1 and 2, 2022, and public sale on October 3, 2022. Carefully crafted and algorithmically generated by our bespoke software, Vera Bradley’s 1982 Collection celebrates the colorful patterns synonymous with the Vera Bradley name. This NFT generative art project is composed of hand drawn layers of art that share the same hallmarks: optimism, happiness, charm, energy, and joy. We like to think of our pattern language as our very own design signature, raising the standards for fashion NFT collectables. Plus, most importantly, 100% of Vera Bradley's primary net proceeds from the 1982 Collection will be donated to the Vera Bradley Foundation for Breast Cancer. It is our hope that everyone in the World of Vera Bradley who acquires this very special 1982 NFT will change out their profile picture background in October to show their support during National Breast Cancer Awareness Month. *Please Note: The exact USD retail price will depend on ETH gas prices at time of purchase.",
      },
      {
        title: "How will I redeem my Jilly Bag?",
        answer: (
          <>
            The owner of the NFT at the end of two weeks (September 28) will be
            the individual able to redeem a Jilly Bag. This will allow enough
            time for secondary trading among Web3 users and is common practice
            among NFT releases. At the end of two weeks, holders will be emailed
            instructions on how to redeem, which will include returning to{" "}
            <Link href={"https://worldofverabradley.com"}>
              worldofverabradley.com
            </Link>{" "}
            to provide their shipping information. Their possession of the NFT
            will be validated through their email address, so they need to be
            sure to provide the email address associated with their purchase. If
            customers are only buying for the Jilly Bag, they can simply hold
            their NFT for two weeks and follow the emailed instructions on
            September 28 to redeem their bag.
          </>
        ),
      },
      {
        title: "What is the return policy?",
        answer:
          "All sales are final. There will be no returns or exchanges offered.",
      },
      {
        title: "Who are our partners?",
        answer: (
          <>
            Minotaur Labs is an end-to-end NFT solution for artists, brands and
            creators. Utilizing its proprietary software, Minotaur Labs provides
            a vertically-integrated platform for NFT production and
            distribution, enabling brands and creatives to build Web3 operating
            systems and large-scale projects on the blockchain. Uniquely
            differentiated, Minotaur Labs provides integrated marketing support
            to ensure its partners enter the metaverse safely, enjoying a
            turn-key experience today, while building the infrastructure for
            tomorrow. <a href={"https://www.minotaur.io/"}>Learn more.</a>
            <br />
            <br />
            Crossmint is a custodial wallet tool that allows for NFTs to be
            purchased with credit card payment. When a customer uses their
            credit card, Crossmint converts their fiat currency to Ethereum,
            Solana or Polygon and mints the NFT on their behalf, which they can
            send to another Ethereum wallet such as Metamask or a ledger for
            added security.{" "}
            <a href={"https://www.crossmint.io/faq/users"}>Learn more.</a>
          </>
        ),
      },
      {
        title: "Where can I get more information?",
        answer: (
          <>
            This World of Vera Bradley website will be updated daily, but should
            you have any further questions, please DM us on Instagram or Twitter
            or get in touch with our team on{" "}
            <a href="https://discord.gg/ud5AU9dNbT">Discord</a>. Follow us on
            Instagram, Twitter and{" "}
            <a href="https://discord.gg/ud5AU9dNbT">Discord</a>.
          </>
        ),
      },
    ],
  },
  {
    title: "How to Purchase",
    questions: [
      {
        title: "Where can I purchase Vera Bradley’s NFT collections?",
        answer:
          "Vera Bradley’s NFT collections are available for purchase in the World of Vera Bradley in both USD and ETH, as well as in the secondary market on OpenSea.",
      },
      {
        title:
          "When can I purchase Vera Bradley’s NFT collections and what is the cost?",
        answer:
          "Launching during fall fashion week, Vera Bradley’s genesis NFT, the Heritage Pass, will be available for purchase via public sale on Wednesday, September 14, 2022. Each NFT will cost approximately $82.00 USD*; the maximum purchase per wallet will be 4 NFTs. The 1982 collection will be available for purchase with presale on October 1 and 2, 2022, and public sale on October 3, 2022. Each NFT will cost approximately $19.82 USD*. *Please Note: The exact USD retail price will depend on ETH gas prices at time of purchase.",
      },
      {
        title: "Can I purchase the Vera Bradley NFT in USD or only in ETH?",
        answer:
          "All Vera Bradley NFTs are available for purchase in either USD with a credit card or in ETH.",
      },
      {
        title: "Why are trust & safety important?",
        answer:
          "There are risks associated with using Internet- and blockchain-based products (like NFT and cryptocurrencies), including, but not limited to, the risk associated with hardware, software and Internet connections, the risk of malicious software introduction, and the risk that third parties may obtain unauthorized access to your third-party wallet or Internet accounts through scams and frauds. While we care deeply about maintaining and protecting the security of all of our users and guarantee a safe shopping experience, you should be aware of those risks and carefully follow the specific guidelines provided by the service provider (eg: Discord Trust & Safety, OpenSea Users Safety, MetaMask Privacy & Security, Sandbox Account and Wallet Security).",
      },
      {
        title: "Disclaimer",
        answer:
          "To use our service, you must use a third-party wallet which allows you to engage in transactions on a blockchain. Vera Bradley does not have custody or control over any blockchain, digital wallet or digital wallet private keys or other third-party technology or services that you may use in connection with accessing our services (“Third-Party Tools”). We are not responsible and shall not be liable for any loss or damages you may incur as a result of using any Third-Party Tools. Any links or references we provide to any Third-Party Tools are for convenience only and we do not approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Tools. All use of Third-Party Tools is at your own risk. NFTs exist only by virtue of the ownership record maintained by the relevant blockchain (e.g., Ethereum network). Any transfers or sales occur on the associated blockchain (e.g., Ethereum). We cannot effect or otherwise control the transfer of ownership of any NFTs or associated digital assets. NFT transactions are generally irreversible. We are not responsible or liable for any sustained losses or injury due to user error or any failure or errant behavior of software (e.g., wallet, smart contract), blockchains, or any other features of the NFTs. You understand and agree that, to the maximum extent permitted by law, the service is provided on an “as is” and “as available” basis and that we expressly disclaim any warranties of any kind, either express or implied.",
      },
    ],
  },
];
